import { useNavigate, NavLink, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import qs from "qs";
import heart from "../../Assets/icons8-heart-50.png";
import search from "../../Assets/icons8-search-24.png";
import star from "../../Assets/icons8-star-filled-50 (1).png";
import cycle from "../../Assets/icons8-cycling-mountain-bike-48.png";
import Swal from "sweetalert2";
import {
  categoryWiseRestaurantsApi,
  cartClearAllApi,
  cartGetApi,
  restaurantImage,
  user,
} from "../../utils/constant";

const Category = () => {
  const navigate = useNavigate();
  const id = useParams("id");
  const [categoryWiseMenu, setCategoryWiseMenuApi] = useState([]);
  const userAddress = JSON.parse(localStorage.getItem("userAddress"));
  const userAddressLatLong = JSON.parse(
    localStorage.getItem("userAddressLatLong")
  );
  const [carttotal, setCarttotal] = useState([]);
  // restaurantDetails api calling
  const categoryWiseMenuApi_f = () => {
    categoryWiseRestaurantsApi(
      qs.stringify({
        userlat: userAddressLatLong?.lat,
        userlng: userAddressLatLong?.lng,
        categoryId: id?.id,
      })
    )
      .then((response) => {
        setCategoryWiseMenuApi(response?.data?.restaurantList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Getcart_f = () => {
    cartGetApi(qs.stringify({ custId: user?.cust_id, coupon_id: "None" }))
      .then((response) => {
        setCarttotal(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const cartClearAllApi_f = () => {
    cartClearAllApi(qs.stringify({ custId: user?.cust_id, coupon_id: "None" }))
      .then((response) => {
        Getcart_f();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Item = (id) => {
    console.log(!carttotal?.restaurant_id);
    if (!carttotal?.restaurant_id) {
      navigate(`/items/${id}`);
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want cleart cart",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          cartClearAllApi_f();
        }
      });
    }
  };
  useEffect(() => {
    categoryWiseMenuApi_f();
    Getcart_f();
  }, []);

  return (
    <>
      <div className="flex gap-5 bg-[#ca1e24] p-4 !bg-gradient-to-r from-red-700 to-orange-600 text-white items-center sticky top-0 z-50 shadow-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 text-white font-extrabold text-xl"
          onClick={() => navigate(-1)}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
          />
        </svg>
        <p className="text-xl font-bold text-white">Items</p>
      </div>

      <div className=" px-2">
        <div className="border-b border-gray-300 mx-2  "></div>

        {/* <div className='text-xl font-bold text-gray-700 py-3 px-2'><p>Popular</p></div> */}
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {categoryWiseMenu &&
            categoryWiseMenu?.map((menuCate) => (
              <>
                <div
                  className="flex py-5 px-2 pb-3 "
                  onClick={(e) => {
                    Item(menuCate?.restaurant_id);
                  }}
                >
                  <div className="relative w-2/5 sm:w-none">
                    {menuCate?.restaurant_image == "" ? (
                      <img
                        src="{logos}"
                        className="w-28 md:w-34 lg:w-38 rounded-md m-auto object-cover"
                      />
                    ) : (
                      <img
                        src={`${restaurantImage}${menuCate?.restaurant_image}`}
                        className="w-28 md:w-34 lg:w-38 rounded-md m-auto shadow-lg shadow-gray-900"
                      />
                    )}
                  </div>
                  <div className="ml-5 w-3/5 sm:w-none">
                    <p className="text-md font-semibold text-orange-700">
                      {menuCate?.restaurant_name}{" "}
                    </p>
                    <p className="text-xs text-gray-700 ">
                      {menuCate?.food_category_name}
                    </p>
                    <p className="text-xs text-gray-700 pt-1">
                      {menuCate?.restaurant_type?.length > 18
                        ? menuCate?.restaurant_type?.slice(0, 15) + "..."
                        : menuCate?.restaurant_type}
                    </p>
                    <div className="text-xs text-gray-700 pt-1 flex">
                      <img src={cycle} className="h-4" />
                      <p className="px-1"> {menuCate?.distance}KM</p>
                    </div>

                    {/* <p className='text-xs text-gray-500 font-light py-2'>Medium Spicy | Gravy | 300 ml | Serves 1</p> */}
                  </div>
                </div>

                {/* <div className="border-b border-gray-300 mx-2  "></div> */}
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default Category;
