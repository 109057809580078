import { useNavigate, NavLink, useParams, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import qs from "qs";
import abc from "../../Assets/icons8-location-30.png";
import bcd from "../../Assets/ef3i0k1ilhdsistcykog.webp";
import veg from "../../Assets/icons8-vegetarian-food-symbol-48.png";
import offer from "../../Assets/icons8-discount-24.png";
import {
  cartGetApi,
  cartUpdateApi,
  cartClearApi,
  saveOrderApi,
  saveOrderDetails_newApi,
  user,
  deliveryChargeApi,
  logosImage,
  saveOrderDetailsApi,
} from "../../utils/constant";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { ToastContainer, toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);
  const [carttotal, setCarttotal] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [deliveryCharge, setDeliveryCharge] = useState([]);
  const [extraSms, setExtraSms] = useState();
  const userAddress = JSON.parse(localStorage.getItem("userAddress"));
  const userAddressLatLong = JSON.parse(
    localStorage.getItem("userAddressLatLong")
  );
  const items = useLocation();
  const Navigate = useNavigate();

  const Payment = () => {
    Navigate("/payment");
  };

  const Address = () => {
    Navigate("/addaddress");
  };

  const Getcart_f = () => {
    cartGetApi(qs.stringify({ custId: user?.cust_id, coupon_id: "None" }))
      .then((response) => {
        setCartItems(response?.data.itemList);
        setCarttotal(response?.data);
        deliveryChargeApi_f(response?.data?.restaurant_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deliveryChargeApi_f = (rId) => {
    deliveryChargeApi(
      qs.stringify({
        userlat: userAddressLatLong?.lat,
        userlng: userAddressLatLong?.lng,
        custId: user?.cust_id,
        restaurantId: rId,
      })
    )
      .then((response) => {
        setDeliveryCharge(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // addToCartApi api calling
  const saveOrderApi_f = (
    e,
    type,
    finalPrice,
    restaurantId,
    restaurantName,
    delivery_charge,
    distance,
    razorpay_payment_id,
    paymentStatus
  ) => {
    if (!selectedAddress) {
      toast.error("Please select delivery address.", { toastId: "2" });
      return;
    }
    if (!cartItems) {
      toast.error("Please select your food.", { toastId: "3" });
      return;
    }
    let selected_product = [];
    cartItems?.map((items) =>
      selected_product.push({
        food_menu_id: items?.food_menu_id,
        food_menu_name: items?.food_menu_name,
        qty: items?.qty,
        extra_item_name: items?.variationList?.[0]?.extra_item_name,
        restaurent_id: restaurantId,
        price: items?.totalPrice,
      })
    );
    // let deliveryAddress = selectedAddress?.address1 selectedAddress?.address2, selectedAddress?.city, selectedAddress?.postcode
    const requestData = {
      customerContactNo: user?.mob_no,
      customerId: user?.cust_id,
      customerName: user?.user_name,
      deliveryAddress: selectedAddress?.address2,
      deliveryCharge: delivery_charge,
      extraMessage: extraSms,
      foodName: cartItems[0]?.food_menu_name,
      paymentType: type,
      restaurantId: restaurantId,
      restaurantName: restaurantName,
      totalAmount: finalPrice,
      totalDistance: distance,
      paymentStatus: paymentStatus,
      transactionId: razorpay_payment_id,
      // "totalAdminPrice": "19",
      selected_product: selected_product,
    };
    saveOrderApi(requestData)
      .then((response) => {
        toast.success(response?.data?.message, { toastId: "5" });
        saveOrderDetailsApi_f(
          e,
          type,
          finalPrice,
          restaurantId,
          restaurantName,
          delivery_charge,
          distance,
          razorpay_payment_id,
          paymentStatus
        );
        // saveOrderApi(response?.data?.menuCategoryList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // saveOrderDetailsApi api calling
  const saveOrderDetailsApi_f = (
    e,
    type,
    finalPrice,
    restaurantId,
    restaurantName,
    delivery_charge,
    distance,
    razorpay_payment_id,
    paymentStatus
  ) => {
    if (!selectedAddress) {
      toast.error("Please select delivery address.", { toastId: "2" });
      return;
    }
    if (!cartItems) {
      toast.error("Please select your food.", { toastId: "3" });
      return;
    }
    let selected_product = [];
    cartItems?.map((items) =>
      selected_product.push({
        food_menu_id: items?.food_menu_id,
        food_menu_name: items?.food_menu_name,
        qty: items?.qty,
        extra_item_name: items?.variationList?.[0]?.extra_item_name,
        restaurent_id: restaurantId,
        price: items?.totalPrice,
      })
    );
    // let deliveryAddress = selectedAddress?.address1 selectedAddress?.address2, selectedAddress?.city, selectedAddress?.postcode
    const requestData = {
      selfPickup: "NO",
      customerId: user?.cust_id,
      customerName: user?.user_name,
      latitude: userAddressLatLong?.lat,
      longitude: userAddressLatLong?.lng,
      coupon_code: "NA",
      coupon_amount: 0,
      deliveryCharge: delivery_charge,
      extraMessage: extraSms,
      foodName: cartItems[0]?.food_menu_name,
      paymentType: type,
      totalDistance: distance,
      paymentStatus: paymentStatus,
      transactionId: razorpay_payment_id,
    };
    saveOrderDetailsApi(requestData)
      .then((response) => {
        // toast.success(response?.data?.message, { toastId: "5" });
        Navigate("/order");
        // saveOrderApi(response?.data?.menuCategoryList)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setSelectedAddress(items?.state?.add);
    Getcart_f();
  }, [items]);

  const Item = (id) => {
    Navigate(`/items/${id}`);
  };
  const selectAddress = (id) => {
    Navigate(`/Savedaddress/checkout`);
  };
  var delivery_charge;
  if (carttotal?.grandTotalPrice > deliveryCharge?.maxOrderAmount) {
    delivery_charge = deliveryCharge?.maxDeliveryCharge;
  } else {
    delivery_charge = deliveryCharge?.minDeliveryCharge;
  }

  const handlePayment = async (
    e,
    type,
    finalPrice,
    restaurantId,
    restaurantName,
    delivery_charge,
    distance
  ) => {
    if (!selectedAddress) {
      toast.error("Please select delivery address.", { toastId: "2" });
      return;
    }
    if (!cartItems) {
      toast.error("Please select your food.", { toastId: "3" });
      return;
    }
    const options = {
      key: "rzp_live_0AjnIg0NSI1UYQ",
      amount:
        (Number(carttotal?.grandTotalPrice) + Number(delivery_charge)) * 100,
      currency: "INR",
      name: carttotal?.restaurant_name,
      description: "Thank you for your order",
      image: logosImage,
      handler: function (response) {
        console.log(response);
        let paymentStatus = "PENDING";
        if (response?.razorpay_payment_id) {
          paymentStatus = "SUCCESS";
        }
        saveOrderApi_f(
          e,
          type,
          finalPrice,
          restaurantId,
          restaurantName,
          delivery_charge,
          distance,
          response.razorpay_payment_id,
          paymentStatus
        );
      },
      prefill: {
        name: user?.user_name,
        email: user?.email,
        contact: user?.mob_no,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // update cart
  const cartUpdateApi_f = (cartQty, cartId) => {
    cartUpdateApi(
      qs.stringify({
        custId: user?.cust_id,
        coupon_id: "None",
        qty: cartQty,
        cartId: cartId,
      })
    )
      .then((response) => {
        Getcart_f();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // clear cart if one product
  const cartClearApi_f = (cartId) => {
    cartClearApi(
      qs.stringify({ custId: user?.cust_id, coupon_id: "None", cartId: cartId })
    )
      .then((response) => {
        Getcart_f();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Quantity Change By User

  let handleIncrement = (cartId, cartQty) => {
    let qt = parseInt(cartQty) + 1;

    if (qt == 0) {
      cartClearApi_f(cartId);
    } else {
      cartUpdateApi_f(qt, cartId);
    }
  };
  let handleDecrement = (cartId, cartQty) => {
    let qt = parseInt(cartQty) - 1;
    if (qt == 0) {
      cartClearApi_f(cartId);
    } else {
      cartUpdateApi_f(qt, cartId);
    }
  };

  return (
    <>
      <div className="bg-[#e9ecee] lg:px-32 px-3 lg:py-10 py-32 m-auto lg:flex-row flex flex-col gap-5 ">
        <div className="lg:w-2/3 w-full">
          <div className="bg-white">
            <div className="p-4 lg:p-8">
              <div className="py-2">
                <p className="bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 font-bold lg:text-2xl md:text-xl text-lg">
                  Select Delivery address
                </p>
                <p className="md:font-medium lg:text-xl md:text-lg text-base text-gray-400">
                  You have a saved address in this location
                </p>
              </div>
              <div className="lg:flex-row flex flex-col gap-5">
                <div className="flex gap-4 p-3 border border-gray-300 lg:w-1/2 w-full hover:shadow-xl">
                  <div>
                    <img src={abc} />
                  </div>
                  <div>
                    <p className="text-base font-semibold">Deliver to Others</p>
                    <p className="text-blue-700 font-light text-gray-400 py-2">
                      {selectedAddress?.address1 ? (
                        selectedAddress?.address1 +
                        "," +
                        selectedAddress?.address2 +
                        ","
                      ) : (
                        <></>
                      )}
                      <br /> {selectedAddress?.city} {selectedAddress?.postcode}
                    </p>
                    <button
                      className="text-white font-semibold px-4 py-2 bg-[#60b246] rounded my-2"
                      onClick={(e) => {
                        selectAddress();
                      }}
                    >
                      Select address
                    </button>
                  </div>
                </div>
                <div className="flex gap-4 p-3 border border-dashed border-gray-300 lg:w-1/2 w-full hover:shadow-xl">
                  <div>
                    <img src={abc} />
                  </div>
                  <div>
                    <p className="text-base font-semibold">Add New Address</p>
                    <p
                      className="text-sm font-light text-gray-400 pb-12"
                      onClick={Address}
                    ></p>
                    <button
                      className="font-semibold px-4 py-2 border border-[#60b246] text-[#60b246] rounded"
                      onClick={() => Navigate("/addaddress")}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:block hidden  text-xl font-bold text-gray-500 lg:mt-6 mt-3 bg-white lg:p-8 p-4">
            <p>Payment</p>
            <div className="my-5 flex gap-28 justify-center bg-gradient-to-r from-red-700 to-orange-600">
              <button
                className="text-white px-4 py-1  rounded my-2"
                onClick={(e) => {
                  saveOrderApi_f(
                    e,
                    "COD",
                    parseInt(carttotal?.grandTotalPrice) +
                      parseInt(delivery_charge),
                    carttotal?.restaurant_id,
                    carttotal?.restaurant_name,
                    delivery_charge,
                    deliveryCharge?.distance
                  );
                }}
              >
                COD
              </button>
              <div className="border-r"></div>
              <button
                className="text-white px-4 py-1 rounded my-2"
                onClick={(e) => {
                  handlePayment(
                    e,
                    "ONLINE",
                    parseInt(carttotal?.grandTotalPrice) +
                      parseInt(delivery_charge),
                    carttotal?.restaurant_id,
                    carttotal?.restaurant_name,
                    delivery_charge,
                    deliveryCharge?.distance
                  );
                }}
              >
                PAY ONLINE{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="lg:w-1/3 w-full bg-white p-4">
          <p className="font-bold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 text-xl">
            {carttotal?.restaurant_name}
          </p>
          <div
            className="flex py-5 justify-between"
            onClick={(e) => {
              Navigate("/");
              // Item(carttotal?.restaurant_id);
            }}
          >
            <p>Your Order</p>
            <p className="text-red-600">Add More Items</p>
          </div>

          {cartItems &&
            cartItems?.map((items) => (
              <>
                <div className="flex  justify-center py-3 justify-around">
                  <div className="flex flex-col justify-center">
                    {items?.variationList[0]?.extra_item_name ? (
                      <p className="text-sm w-28 text-gray-700">
                        {items?.food_menu_name}
                        <br />
                        <span className="text-red-800">
                          ({items?.variationList[0]?.extra_item_name})
                        </span>
                      </p>
                    ) : (
                      <p className="text-sm w-28 text-gray-700">
                        {" "}
                        {items?.food_menu_name}
                        <br />
                      </p>
                    )}
                  </div>
                  <p className="text-sm">Rs.{items?.totalPrice}</p>
                  <div className=" rounded">
                    <span
                      className="bg-orange-600 rounded-full bg-red-800 text-white  px-1"
                      onClick={() =>
                        handleDecrement(items?.cart_id, items?.qty)
                      }
                    >
                      -
                    </span>
                    <span className="mx-1">{items.qty}</span>

                    <span
                      className="bg-orange-600 rounded-full bg-red-800 px-1 text-white"
                      onClick={() =>
                        handleIncrement(items?.cart_id, items?.qty)
                      }
                    >
                      +
                    </span>
                  </div>

                  <Delete
                    className=""
                    onClick={() => cartClearApi_f(items?.cart_id)}
                  />
                </div>
                <div></div>
              </>
            ))}
          <div className="border-dashed border-b my-2 border-gray-300"></div>
          <div className="flex justify-between text-sm text-gray-500 py-2">
            <p>Item Total</p> <p>Rs.{carttotal?.grandTotalPrice}</p>
          </div>
          <div className="flex justify-between text-sm text-gray-500 py-2">
            <p>Delivery Fee</p>{" "}
            <p>
              Rs.{" "}
              {carttotal?.grandTotalPrice
                ? carttotal?.grandTotalPrice > deliveryCharge?.maxOrderAmount
                  ? deliveryCharge?.maxDeliveryCharge
                  : deliveryCharge?.minDeliveryCharge
                : 0}
            </p>
          </div>

          <div className="border border-b my-3 border-gray-500"></div>
          <div className="flex justify-between font-semibold py-2 bg-white">
            <p>To Pay</p>{" "}
            <p>
              Rs.{" "}
              {carttotal?.grandTotalPrice
                ? parseInt(carttotal?.grandTotalPrice) +
                  parseInt(delivery_charge)
                : 0}
            </p>
          </div>
          <div className="border border-b my-3 border-gray-500"></div>
        </div>
        <div className="lg:hidden  text-xl font-bold text-gray-500 lg:my-6 mt-3 bg-white lg:p-8 p-4">
          <p>Payment</p>
          <div className="my-5 flex justify-center bg-gradient-to-r from-red-700 to-orange-600">
            <button
              className="text-white px-4 py-1 rounded my-2"
              onClick={(e) => {
                saveOrderApi_f(
                  e,
                  "COD",
                  parseInt(carttotal?.grandTotalPrice) +
                    parseInt(delivery_charge),
                  carttotal?.restaurant_id,
                  carttotal?.restaurant_name,
                  delivery_charge,
                  deliveryCharge?.distance
                );
              }}
            >
              COD
            </button>
            <div className="border-r"></div>
            <button
              className="text-white px-4 py-1  rounded my-2"
              onClick={(e) => {
                handlePayment(
                  e,
                  "ONLINE",
                  parseInt(carttotal?.grandTotalPrice) +
                    parseInt(delivery_charge),
                  carttotal?.restaurant_id,
                  carttotal?.restaurant_name,
                  delivery_charge,
                  deliveryCharge?.distance
                );
              }}
            >
              PAY ONLINE{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
