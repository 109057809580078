import jkl from "../../Assets/2b4f62d606d1b2bfba9ba9e5386fabb7.webp";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";

const ViewAll = () => {
  const navigate = useNavigate();
  const typeOfUrl = useParams("type");
  const [viewAll, setViewAll] = useState([]);
  // ViewAll api calling
  const viewAll_f = () => {
    axios
      .post(
        `https://brbun.in/super_admin/brbun_web_services/brbun_customer/${typeOfUrl?.type}.php`,
        qs.stringify({ userlat: "21.321705", userlng: "74.891239" })
      )
      .then((response) => {
        console.log(response?.data?.restaurantList);
        setViewAll(response?.data?.restaurantList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    viewAll_f();
  }, []);
  return (
    <>
      <div>
        <div className="flex gap-5 bg-gradient-to-r from-red-700 to-orange-600 p-4 text-white items-center sticky top-0 z-50 shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-white font-extrabold text-xl"
            onClick={() => navigate(-1)}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
          <p className="text-xl font-bold text-white">View All</p>
        </div>

        <div className="pt-1 grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
          {viewAll &&
            viewAll?.map((view) => (
              <div className="gap-2 p-1">
                <div>
                  <img
                    src={jkl}
                    className="w-30 h-20 md:w-34 md:h-24 lg:w-38 lg:h-28 rounded-md m-auto"
                  />
                </div>
                <div>
                  <p className="bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 font-semibold w-4/5 m-auto">
                    {view?.restaurant_name}
                  </p>
                  <p className="text-xs font semibold w-4/5 m-auto">
                    {view?.food_category_name}
                  </p>
                  <p className="text-xs text-gray-600 w-4/5 m-auto">
                    {view?.restaurant_type}
                  </p>
                  <p className="text-xs text-gray-600 w-4/5 m-auto">
                    {view?.distance} KM
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ViewAll;
