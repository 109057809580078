import React from "react";
import apple from "../../Assets/icon-AppStore_lg30tv.webp";
import android from "../../Assets/icon-GooglePlay_1_zixjxl.webp";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logo-wide.png";

const Footer = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="lg:py-10 grid lg:grid-cols-5 gap-16 grid-cols-2 justify-center lg:p-3 p-5 text-white pt-10  bg-black">
      
        <img src={logo} alt="" className="lg:ml-8 p-2 bg-white lg:h-15  rounded-md" />
     
        
        <div className="flex flex-col gap-2 ">
          <p className="font-semibold text-gray-400 py-2 text-lg">Legal</p>
          <p onClick={()=>navigate("/help")}>Help & Support</p>
          <p><a  href="https://brbun.com/privacy-pollicy.html">Privacy Policy</a></p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-semibold text-gray-400 py-2 text-lg">Contact</p>
         
          <p> CALL US TODAY!</p>
          <p>+(91) 9075746516</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-semibold text-gray-400 py-2 text-lg">Opening</p>
         
          <p> WE ARE OPEN!</p>
          <p>Mon - Sun (9:00 - 22:00)</p>
          

        </div>
        <div className="flex flex-col gap-2 lg:p-0 p-4">
          <img src={apple} className="lg:h-12 h-6 lg:w-36 w-20" alt="" />
          <img src={android} className="lg:h-12 h-6 lg:w-36 w-20" alt="" />
        </div>
      </div>
    </>
  );
};

export default Footer;
