import { useNavigate, NavLink, useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import phone from "../../Assets/pngwing.com (14).png";
import { get_help_contactApi } from '../../utils/constant';
import qs from 'qs';

const Help = () => {
  const id = useParams('id');
  const [help, sethelp] = useState([]);

  const help_f = () => {
    get_help_contactApi(qs.stringify({ restaurantId: id?.id  }))
      .then((response) => {
        sethelp(response.data.contact_list)
      })
      .catch((error) => {
        console.log(error);
      });
  };
useEffect(()=>{
  help_f();
},[])
  
  return (
    <>
      <div className="lg:py-20 py-32">
        <div className="flex justify-center items-center">
          <div className="text-2xl flex flex-col justify-center items center ">
            <div className="lg:text-3xl text-gray-500 py-6 font-bold">
              <p className='px-5'>Need Help ? You Can Call Us At</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center py-5 pb-8 gap-4">
          {help?.map((item)=>{
            return<>
              <div className="flex gap-1 p-3 shadow-lg rounded-md px-5 bg-gray-100 my-2 text-lg font-bold text-gray-600 hover:scale-125 transition transition-duration-2000">
              <img src={phone} className="w-7 h-7" />
              <p>{item?.contact_no}</p>
            </div>
          
            </>
         })}
         
        </div>
      </div>
    </>
  );
};

export default Help;
