import { useNavigate, NavLink, useParams, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import qs from "qs";
import { addAddressApi, updateAddressApi } from "../../utils/constant";
import Geocode from "react-geocode";
import { LocationOn, MyLocation } from "@mui/icons-material";
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { List, MenuItem } from "@mui/material";
import classNames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SelectAddress = () => {
  const navigate = useNavigate();
  const type = useParams("type");
  const items = useLocation();
  const [latLong, setLatLong] = useState();
  const [updateFromSaveAdd, setUpdateFromSaveAdd] = useState([]);
  const [address2, setaddress2] = useState();
  const [address1, setaddress1] = useState();
  const [city2, setCity2] = useState();
  const [countryByMapOnClick, setcCountryByMapOnClick] = useState();
  const [stateByMapOnClick, setStateByMapOnClick] = useState();
  const [addAddress, setAddAddress] = useState([
    {
      custId: "",
      fullName: "",
      mobile: "",
      address2: "",
      city: "",
      postcode: "",
      addressType: "",
      latitude: "",
      longitude: "",
    },
  ]);
  var myArray;
  const handleChangeforType = (type) => {
    setAddAddress({
      ...addAddress,
      addressType: type,
    });
  };

  // ####################### start

  const [open, setOpen] = useState(false);
  // const [selectedLocation, setSelectedLocation] = useState({
  //   lat: 21.36223000,
  //   lng: 74.89897800,
  // });
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [address, setAddress] = useState("");
  const libraries = ["places"];
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setSelectedLocation(latLng);
  };

  const apiKey = "AIzaSyAO3TRgzZx5X3i6tTcuNMjCvvCxhWe4M3g";

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) =>
      setSelectedLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    );
  };

  // ####################### end

  Geocode.setApiKey("AIzaSyAO3TRgzZx5X3i6tTcuNMjCvvCxhWe4M3g");
  Geocode.setLanguage("en");

  const position = async () => {
    Geocode.fromLatLng(selectedLocation?.lat, selectedLocation.lng).then(
      (response) => {
        const address = response?.results[0]?.formatted_address;
        setaddress1(address);
        myArray = address.split(",");
        if (myArray[2]) {
          setCity2(myArray[1]);
        } else if (myArray[1]) {
          setCity2(myArray[1]);
        }
        setaddress2(myArray.toString());
        setcCountryByMapOnClick(myArray[myArray?.length - 1]);
        if (myArray?.length == 2) {
          setStateByMapOnClick(myArray[myArray?.length - 2]);
          setCity2(myArray[0]);
          setAddAddress({
            ...addAddress,
            address2: myArray[myArray?.length - 2],
          });
        }
        localStorage.setItem("userAddress", JSON.stringify(myArray));

        console.log(
          address,
          "++++++++++++++++++++++++++++++++++++++++++++",
          myArray,
          myArray?.length,
          city2
        );
      },
      (error) => {
        console.error(error);
      }
    );
  };
  useEffect(() => {
    position();
  }, [selectedLocation]);
  useEffect(() => {
    getLocation();
  }, []);

  const savedAddress = () => {
    localStorage.setItem(
      "userAddressLatLong",
      JSON.stringify(selectedLocation)
    );
    navigate(-1);
  };
  console.log(address, "=====================");
  return (
    <>
      <div className="flex gap-5 bg-gradient-to-r from-red-700 to-orange-600 p-4 text-white items-center sticky top-0 z-50 shadow-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 text-white font-extrabold text-xl"
          onClick={() => navigate(-1)}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
          />
        </svg>
        <p className="text-xl font-bold text-white">Choose Address</p>
      </div>

      <div className="">
        <div className="lg:px-40">
          <div className="p-3">
            <LoadScript libraries={libraries} googleMapsApiKey={apiKey}>
              <StandaloneSearchBox>
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className="py-3">
                      <span className="flex lg:flex-row justify-center flex-col gap-1 ">
                        <input
                          {...getInputProps({ placeholder: "Search..." })}
                          className="outline-none border-red-800 p-1.5 border-2 rounded px-5 lg:w-1/2 w-full"
                        />
                      </span>

                      <List
                        className={classNames(
                          "!z-50 p-5  !fixed shadow !rounded",
                          address !== 0 ? "block" : "hidden"
                        )}
                      >
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#ebebeb"
                              : "#fff",
                          };
                          return (
                            <MenuItem
                              key={suggestion.placeId}
                              onClick={() =>
                                console.log(suggestion.description)
                              }
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </MenuItem>
                          );
                        })}
                      </List>
                    </div>
                  )}
                </PlacesAutocomplete>
              </StandaloneSearchBox>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={selectedLocation}
                zoom={10}
                onClick={(event) => {
                  setSelectedLocation({
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                  });
                }}
              >
                <Marker position={selectedLocation} />
              </GoogleMap>
            </LoadScript>
            <p>Address</p>
            <p className="py-1 text-base font-semibold text-gray-600">
              <LocationOn />
              {address1}
            </p>
            <div className="pt-2">
              <div className="flex justify-center items-center mt-4 mx-3">
                <button
                  className="font-semibold text-base text-white px-3 p-2 bg-green-400 w-full rounded-lg"
                  onClick={(e) => {
                    savedAddress();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectAddress;
