import React, { useEffect , useState } from 'react'
import qs from 'qs';
import { List, MenuItem } from "@mui/material";
import { useNavigate, NavLink } from "react-router-dom";
import {searchApi , user}from '../../utils/constant';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const  Search = () =>{
  const navigate = useNavigate()
  const [searchingApi, setSearchingApi] = useState([])
  const [searchingValue, setSearchingValue] = useState()
  const userAddress = JSON.parse(localStorage.getItem("userAddress"));
  const userAddressLatLong = JSON.parse(localStorage.getItem("userAddressLatLong"));
     // searchApi api calling
  const searchApi_f = (searchingValue) => {
    if (!searchingValue) {
      return
    }
    searchApi(qs.stringify({  userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, serach_keyword: searchingValue }))
      .then((response) => {
        setSearchingApi(response?.data?.searchList)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (value) => {
    setSearchingValue(value)
    if (!value) {
      setSearchingApi([])
    }
  }
  useEffect(() => {
    searchApi_f(searchingValue)
  }, [searchingValue]);
 
      useEffect(()=>{
        searchApi_f();
      })

  return (
    <div>
       
        <div className='flex justify-around mt-2  items-center border rounded-full '>
          <div className='flex gap-3 '>
            <input onChange={(e) => handleChange(e.target.value)} type='search' placeholder='Restaurant name or a dish...' className='w-96 h-4 outline-none my-2 text-center' /></div>
       
        </div>
         {/* <Autocomplete
      
        id="Search Resturants"
        disableCloseOnSelect
        renderInput={({searchingValue}) => (
          <TextField  label="Search Resturants" variant="standard"  onChange={(e) => handleChange(e.target.value)} />
        )}
      /> */}
      
      <List
        className={(
          "!z-50 p-5  !fixed shadow !rounded",
          searchingApi !== 0 ? "block" : "hidden"
        )}
      >
        {searchingApi?.map((searchingData) => {
        
          return (
            <MenuItem
              key={searchingData}
            
         onClick={()=>navigate(`/items/${searchingData.restaurant_id}`)}   >
              {searchingData.restaurant_name}
            </MenuItem>
          );
        })}
      </List>
        
    
    </div>
  )
}

export default Search