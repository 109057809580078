import {  useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import upi from "../../Assets/icons8-bhim-upi-50.png";
import card from "../../Assets/icons8-debit-card-50.png";
import wallet from "../../Assets/icons8-wallet-30.png";
import banking from "../../Assets/icons8-banking-24.png";
import { useNavigate } from "react-router-dom";
import {insert_feedbackApi } from '../../utils/constant';
import qs from 'qs';
import { Textarea } from 'flowbite-react';
import { user } from '../../utils/constant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AddFeedback = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [number , setNumber]  = useState("");
  const [feed , setfeed]  = useState("");
 

  const feed_f = () => {
    insert_feedbackApi(qs.stringify({ custId: user?.cust_id, custName: user?.user_name, customerContactNumber: user?.mob_no, feedback: feed }))
    .then((response) => {
      setfeed(response.data.message)
      if (response?.data?.status == 1) { 
        toast.success(response?.data?.message, { toastId: "6" });
        navigate('/')
      }
     

    })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    feed_f();
  }, [])

const userLogOutApi_f = () => {
  localStorage.clear();
  navigate('/')
}
  return (
    <>
    <div className="lg:py-16 py-32">
        <div className="lg:px-20 px-3 lg:py-10 py-3 flex  lg:flex-row flex-col justify-center lg:gap-40 gap-4">
          <div className="lg:w-1/4 w-full bg-[#edf1f7] pl-2 py-2">
            <div
              className="flex gap-3 p-4 ml-2 mt-2 cursor-pointer"
              onClick={() => navigate("/order")}
            >
              <img src={upi} className="w-8 h-8" alt="" />
              <p className="text-xl font-semibold text-gray-600">My Orders</p>
            </div>
            <div
              className=" flex gap-3 p-4 ml-2 mt-2 "
              onClick={() => navigate("/profile")}
            >
              <img src={banking} className="w-8 h-8" alt="" />
              <p className="text-xl font-semibold text-gray-600">My Profile</p>
            </div>
            <div
              className="flex gap-3 p-4 ml-2 bg-white cursor-pointer"
              onClick={() => navigate("/addfeedback")}
            >
              <img src={banking} className="w-8 h-8" alt="" />
              <p className="text-xl font-semibold text-gray-600">Add Feeback</p>
            </div>
            <div className="flex gap-3 p-4 ml-2 mb-2 ">
  <img src={card} className="w-8 h-8" alt="" />
  <p className="text-xl font-semibold text-gray-600" onClick={(e) => userLogOutApi_f(e)} >Logout</p>
 
</div>
          </div>

          <div className="flex flex-col justify-center items-center  pl-0">
            <div className="text-gray-500 font-bold">
              <p>Please Give Your Feedback Here 👇</p>
            </div>
           
    
      
       <Textarea
        name="Feedback"
         required
         type=""
         placeholder='Feedback'
         className='border my-2 p-2 border-gray-400 rounded text-center'
         onChange={(e) => setfeed(e.target.value)}
       />
       <div>
        <button
                type="submit"
                className="px-3 p-1 bg-green-400 text-white hover:shadow-md text-lg font-semibold rounded-md"
                onClick={feed_f}  >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFeedback;
