import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import qs from 'qs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userRegistrationApi, verifyOtpApi, loginSendOtpApi, resendOtpApi } from '../../utils/constant';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ArrowBack, CloseOutlined, Person } from "@mui/icons-material";
import { Divider } from "@mui/material";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
}
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
}
function Login() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(true);
  const [openLogin, setOpenLogin] = useState(false);
  const [otp, setOtp] = useState('');

  const navigate = useNavigate();
  // Sign Up UseState

  const [email, setEmail] = useState("");
  const [emailValidError, setEmailValidError] = useState(false);
  const [emailIsRequiredError, setEmailIsRequiredlError] = useState(false);

  const [name, setName] = useState("");
  const [nameValidError, setnameValidError] = useState(false);
  const [nameIsRequiredError, setnameIsRequiredlError] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phonelIsRequiredError, setPhoneIsRequiredlError] = useState(false);

  const [mobileOTP, setmobileOTP] = useState("");
  const [otpValidError, setotpValidError] = useState(false);

  const [signCheck, setSignCheck] = useState(false);
  const notify = () => toast("Wow so easy!");


  //  handle Email 
  const handleEmail = (value) => {
    const rex = /^[A-Za-z0-9._]{3,}@[A-Za-z]{3,12}[.]{1}[A-Za-z.]{2,6}$/;
    if (value === "") {
      setEmailValidError(false);
    } else if (rex.test(value) === false) {
      setEmailValidError(true);
    } else {
      setEmailValidError(false);
    }
    setEmail(value);
  };

  // Handle Name
  const handleName = (value) => {
    const regex = /^[a-zA-Z0-9]+$/;

    if (value === "") {
      setnameValidError(false);
      setnameIsRequiredlError(true);
    } else if (regex.test(value) === false) {
      // setnameValidError(true);
      setnameIsRequiredlError(false);
      return;
    } else {
      setnameValidError(false);
      setnameIsRequiredlError(false);
    }
    setName(value);
  };
  //  handle phone number
  const handlePhone = (value) => {
    if (value.length > 10) {
      return;
    }
    if (/^\d{0,}?$/.test(value)) {
      setPhone(value);
    }
    const regex = /^[6-9]\d{9}$/;
    if (value === "") {
      setPhoneError(false);
      setPhoneIsRequiredlError(true);
    } else if (regex.test(value) === false) {
      setPhoneError(true);
      setPhoneIsRequiredlError(false);
    } else {
      setPhoneError(false);
      setPhoneIsRequiredlError(false);
    }
  };

  // Handle Mobile OTP
  const handleMobileOTP = (value) => {
    if (value.length > 10) {
      return;
    }
    if (/^\d{0,}?$/.test(value)) {
      setmobileOTP(value);
    }
    const pattern = /[0-9]/;
    if (pattern.test(value) === false) {
      setotpValidError(true);
    } else {
      setotpValidError(false);
    }
  };
  const handleTermsCond = (e) => {
    e.preventDefault();
    navigate('/termsConditions'
      // { state: { setSignUp } }
    );

  }

  const verifyOtpApi_f = () => {
    if (mobileOTP?.length != 5) {
      toast.error("Please enter correct OTP.", { toastId: "2" });
      return
    }
    verifyOtpApi(qs.stringify({ mobileNumber: phone, otp: mobileOTP }))
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.status == 1) {
          toast.success(response?.data?.message, { toastId: "6" });
          setOpenOtp(false)
          localStorage.setItem(
            "userData",
            JSON.stringify(response?.data)
          );
          localStorage.setItem(
            "userTokenOfBrbun",
            JSON.stringify(response?.data?.userToken)
          );
          localStorage.setItem(
            "cust_id",
            JSON.stringify(response?.data?.cust_id) && handleClose()
          );
          navigate('/')
        }
        if (response?.data?.status == 0) {
          toast.error(response?.data?.message, { toastId: "5" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userRegistrationApi_f = () => {
    if (email != "") {
      const rex = /^[A-Za-z0-9._]{3,}@[A-Za-z]{3,12}[.]{1}[A-Za-z.]{2,6}$/;
      if (rex.test(email) === false) {
        toast.error("Please enter correct email.", { toastId: "3" });
        setEmailValidError(true);
        return;
      }
    }
    if (name === "" || !name) {
      toast.error("Name is required.", { toastId: "4" });
      return;
    }
    if (name?.length <= 1) {
      toast.error("Please enter correct name.", { toastId: "2" });
      return
    }
    if (phone === "" || !phone) {
      toast.error("Mobile is required.", { toastId: "2" });
      return;
    }
    if (phone.length !== 10 || phoneError) {
      toast.error("Please enter correct mobile.", { toastId: "2" });
      return
    }
    if (signCheck === false) {
      toast.error("Please accept T&C and Privacy Policy.", { toastId: "1" });
      return;
    }
    if ((name !== "" && phone !== "" && phone.length === 10 && phonelIsRequiredError === false && phoneError === false)) {
      userRegistrationApi(qs.stringify({
        mobileNumber: phone,
        name: name,
        emailId: email,
      }))
        .then((response) => {
          console.log(response);
          if (response?.data?.status == 1) {
            toast.success(response?.data?.message, { toastId: "6" });
            setOpenOtp(true)
            setOpenRegistration(false)
          }
          if (response?.data?.status == 0 || response?.data?.status == 2) {
            toast.error(response?.data?.message, { toastId: "5" });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error, { toastId: "7" });
        });
    }
  };
  const loginSendOtpApi_f = () => {
    if (phone === "" || !phone) {
      toast.error("Mobile is required.", { toastId: "2" });
      return;
    }
    if (phone.length !== 10 || phoneError) {
      toast.error("Please enter correct mobile.", { toastId: "2" });
      return
    }
    if ((phone !== "" && phone.length === 10 && phonelIsRequiredError === false && phoneError === false)) {
      loginSendOtpApi(qs.stringify({
        mobileNumber: phone,
      }))
        .then((response) => {
          console.log(response);
          if (response?.data?.status == 1) {
            toast.success(response?.data?.messege, { toastId: "6" });
            setOpenOtp(true)
            setOpenLogin(false)
          }
          if (response?.data?.status == 0 || response?.data?.status == 2) {
            toast.error(response?.data?.messege, { toastId: "5" });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error, { toastId: "7" });
        });
    }
  };
  const resendOtpApi_f = () => {
    if (phone === "" || !phone) {
      toast.error("Mobile is required.", { toastId: "2" });
      return;
    }
    if (phone.length !== 10 || phoneError) {
      toast.error("Please enter correct mobile.", { toastId: "2" });
      return
    }
    if ((phone !== "" && phone.length === 10 && phonelIsRequiredError === false && phoneError === false)) {
      resendOtpApi(qs.stringify({
        mobileNumber: phone,
      }))
        .then((response) => {
          console.log(response);
          if (response?.data?.status == 1) {
            toast.success(response?.data?.messege, { toastId: "6" });
            setOpenOtp(true)
            setOpenLogin(false)
          }
          if (response?.data?.status == 0 || response?.data?.status == 2) {
            toast.error(response?.data?.messege, { toastId: "5" });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error, { toastId: "7" });
        });
    }
  };
  return (
    <>

      <p>
        {localStorage.getItem("userTokenOfBrbun") ? (
          <>
            <img src="https://www.fit2work.com.au/assets/img/avatars/LoginIconAppl.png" className="h-8 mt-2 "
              alt=""
              onClick={() => navigate("/profile")}
            />
          </>
        ) : (
          <p onClick={handleOpen}>
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUTOokXJzVE0aOwt2d7Vw8OsVP8KcxAU_GINEYzxVoGnVj9ZMBVtAXstBCTSurIDV0yqg&usqp=CAU"
              className="h-6 mt-3" />
          </p>
        )}
      </p>
      <Modal
        className="lg:block hidden"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <div className='lg:flex gap-10'>
            <div className="lg:block hidden flex flex-col ">
              <img src="https://website.brbun.in/static/media/logo-wide.24956f10ef231cf92493.png" className="h-10  px-20 animate-bounce " />

              <img src="https://img.freepik.com/free-vector/user-verificat…n-password-safety-measures_335657-3530.jpg" className="h-96 " />
            </div>

            <div className=" ">

              {openRegistration &&
                <>
                  <CloseOutlined className="lg:ml-80" onClick={handleClose} />
                  <h1 className="text-center text-xl !font-bold text-red-800 py-2">Signup</h1>
                  <p className="lg:block hidden py-1 text-center ">Enter your email address to your account on <span className="text-red-800 !font-bold">Brbun</span></p>
                  <div className='flex flex-col '>
                    <p className="pt-2">User Name :</p>
                    <input
                      maxLength="20"
                      name="name"
                      value={name}
                      onChange={(e) => handleName(e.target.value)}
                      type='text' placeholder='Name' className='rounded text-center lg:w-auto border border-red-800' />

                    <p className="pt-1">Phonenumber :</p>
                    <input type='text'
                      maxLength="10"
                      name="phone"
                      value={phone}
                      onChange={(e) => handlePhone(e.target.value)}
                      placeholder='Mobile' className='rounded text-center  lg:w-auto border border-red-800' />

                    <p className='font-semibold'>Email</p>
                    <input type='text'
                      maxLength="40"
                      name="email"
                      value={email}
                      onChange={(e) => handleEmail(e.target.value)}
                      placeholder='Email' className='rounded text-center lg:w-auto border border-red-800' />

                    <div className=' flex gap-3'>
                      <input
                        className=" border border-red-800"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={(e) => setSignCheck(e.target.checked)}
                      />
                      <p className="pt-2 text-xs">I accept the terms Conditions and I have read the privacy Policy .</p>
                    </div>
                  </div>


                  <button className='my-2 p-1 text-white w-full rounded-full bg-red-800' onClick={(e) => userRegistrationApi_f(e)} >Create Account</button>

                  <Divider />
                  <p className='text-center '>(OR)</p>
                  <div className="flex gap-16">
                    <p className='p-1 text-sm '> You have already an account</p>

                    <button className="bg-red-800 p-1 text-white rounded-full" onClick={(e) => { setOpenLogin(true); setOpenRegistration(false); setPhone('') }} >Login</button>
                  </div>
                </>
              }
              {openOtp &&
                <div className="flex flex-col justify-center">
                  <CloseOutlined className="lg:ml-80" onClick={() => setOpen(false)} />
                  <p className="!text-xl py-4  !font-bold !text-center ">
                    Enter OTP sent to your mobile number
                  </p>
                  <span className="!font-bold !text-center animate-pulse text-red-800">({phone})</span>
                  <p className="py-2">Enter OTP :</p>
                  <input type="text"
                    maxLength="5"
                    name="mobileOTP"
                    placeholder="Enter OTP"
                    value={mobileOTP}
                    onChange={(e) => handleMobileOTP(e.target.value)}
                    className="text-center rounded  border border-red-800 p-1" />
                  <p className='py-5 text-center  text-red-800 !font-bold' onClick={(e) => resendOtpApi_f(e)}>Resend OTP</p>

                  <button className='text-white bg-[#ca1e24] p-1' onClick={(e) => verifyOtpApi_f(e)}>Submit</button>


                </div>
              }
              {openLogin &&
                <>


                  <CloseOutlined className="lg:ml-80" onClick={() => setOpen(false)} />
                  <div className="flex flex-col">
                    <p className="text-center text-2xl py-5 !font-bold">Enter your mobile number</p>
                    <p className='py-2 pt-5'>Mobile Number :</p>
                    <input type='text'
                      maxLength="10"
                      name="phone"
                      value={phone}
                      onChange={(e) => handlePhone(e.target.value)}
                      placeholder='Mobile' className=' text-center rounded p-1 border border-red-800' />


                    <button className='text-white bg-[#ca1e24] p-1 my-10' onClick={(e) => loginSendOtpApi_f(e)} >Login</button>


                  </div>


                </>
              }
            </div>
          </div>
        </Box>

      </Modal>
      <Modal
        className="lg:hidden"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} >
          <div className='lg:flex gap-10'>
            <div className="lg:block hidden flex flex-col ">
              <img src="https://website.brbun.in/static/media/logo-wide.24956f10ef231cf92493.png" className="h-10  px-20 animate-bounce " />

              <img src="https://img.freepik.com/free-vector/user-verificat…n-password-safety-measures_335657-3530.jpg" className="h-96 " />
            </div>

            <div className=" ">

              {openRegistration &&
                <>
                  <CloseOutlined className="lg:ml-80" onClick={handleClose} />
                  <h1 className="text-center text-xl !font-bold text-red-800 py-2">Signup</h1>
                  <p className="lg:block hidden py-1 text-center ">Enter your email address to your account on <span className="text-red-800 !font-bold">Brbun</span></p>
                  <div className='flex flex-col '>
                    <p className="pt-2">User Name :</p>
                    <input
                      maxLength="20"
                      name="name"
                      value={name}
                      onChange={(e) => handleName(e.target.value)}
                      type='text' placeholder='Name' className='rounded text-center lg:w-auto border border-red-800' />

                    <p className="pt-1">Phonenumber :</p>
                    <input type='text'
                      maxLength="10"
                      name="phone"
                      value={phone}
                      onChange={(e) => handlePhone(e.target.value)}
                      placeholder='Mobile' className='rounded text-center  lg:w-auto border border-red-800' />

                    <p className='font-semibold'>Email</p>
                    <input type='text'
                      maxLength="40"
                      name="email"
                      value={email}
                      onChange={(e) => handleEmail(e.target.value)}
                      placeholder='Email' className='rounded text-center lg:w-auto border border-red-800' />

                    <div className=' flex gap-3'>
                      <input
                        className=" border border-red-800"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={(e) => setSignCheck(e.target.checked)}
                      />
                      <p className="pt-2 text-xs">I accept the terms Conditions and I have read the privacy Policy .</p>
                    </div>
                  </div>
                   <button className='my-2 p-1 text-white w-full rounded-full bg-red-800' onClick={(e) => userRegistrationApi_f(e)} >Create Account</button>

                  <Divider />
                  <p className='text-center '>(OR)</p>
                  <div className="flex gap-16">
                    <p className='p-1 text-sm '> You have already an account</p>

                    <button className="bg-red-800 p-1 text-white rounded-full" onClick={(e) => { setOpenLogin(true); setOpenRegistration(false); setPhone('') }} >Login</button>
                  </div>
                </>
              }
              {openOtp &&
                <div className="flex flex-col justify-center">
                  <CloseOutlined className="lg:ml-80" onClick={() => setOpen(false)} />
                  <p className="!text-xl py-4  !font-bold !text-center ">
                    Enter OTP sent to your mobile number
                  </p>
                  <span className="!font-bold !text-center animate-pulse text-red-800">({phone})</span>
                  <p className="py-2">Enter OTP :</p>
                  <input type="text"
                    maxLength="5"
                    name="mobileOTP"
                    placeholder="Enter OTP"
                    value={mobileOTP}
                    onChange={(e) => handleMobileOTP(e.target.value)}
                    className="text-center rounded  border border-red-800 p-1" />
                  <p className='py-5 text-center  text-red-800 !font-bold' onClick={(e) => resendOtpApi_f(e)}>Resend OTP</p>

                  <button className='text-white bg-[#ca1e24] p-1' onClick={(e) => verifyOtpApi_f(e)}>Submit</button>


                </div>
              }
              {openLogin &&
                <>


                  <CloseOutlined className="lg:ml-80" onClick={() => setOpen(false)} />
                  <div className="flex flex-col">
                    <p className="text-center text-2xl py-5 !font-bold">Enter your mobile number</p>
                    <p className='py-2 pt-5'>Mobile Number :</p>
                    <input type='text'
                      maxLength="10"
                      name="phone"
                      value={phone}
                      onChange={(e) => handlePhone(e.target.value)}
                      placeholder='Mobile' className=' text-center rounded p-1 border border-red-800' />


                    <button className='text-white bg-[#ca1e24] p-1 my-10' onClick={(e) => loginSendOtpApi_f(e)} >Login</button>


                  </div>


                </>
              }
            </div>
          </div>
        </Box>

      </Modal>



      <ToastContainer />


    </>
  );
}

export default Login;

