import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/Home";
import Layout from "./Layout";
import ItemsPage from "./Pages/Items";
import Checkout from "./Pages/Checkout";
import PaymentPage from "./Pages/payment";
import AddAddress from "./Pages/AddAddress";
import Help from "./Pages/Help";
import ProfilePage from "./Pages/ProfilePage";
import Order from "./Pages/Order";
import OrderDetail from "./Pages/Order/OrderDetail";
import SavedAddresses from "./Pages/SavedAddresses";
import AddFeedback from "./Pages/AddFeedback";
import ViewAll from "./Pages/ViewAll";
import Category from "./Pages/Category";
import Login from "./Pages/Login";
import SelectAddress from "./Pages/SelectAddress";
import { useState } from "react";

function App() {
  const [response, setResponse] = useState(false);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/Category/:id" element={<Category />} />
          <Route path="/" element={<Layout component={<HomePage />} />} />

          <Route
            path="/items"
            element={
              <Layout
              response={response}
                component={
                  <ItemsPage response={response} setResponse={setResponse} />
                }
              />
            }
          />
             <Route
            path="/items/:id"
            element={
              <Layout
              response={response}
                component={
                  <ItemsPage response={response} setResponse={setResponse} />
                }
              />
            }
          />
        
          <Route
            path="/checkout"
            element={<Layout component={<Checkout />} />}
          />
          <Route
            path="/payment"
            element={<Layout component={<PaymentPage />} />}
          />
          <Route
            path="/addaddress"
            element={<Layout component={<AddAddress />} />}
          />
          <Route path="/help" element={<Layout component={<Help />} />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/profile"
            element={<Layout component={<ProfilePage />} />}
          />
          <Route path="/order" element={<Layout component={<Order />} />} />
          <Route path="/orderdetail/:order_id" element={<Layout component={<OrderDetail />} />} />
          <Route path="/savedaddress/:type" element={<SavedAddresses />} />
          <Route
            path="/addfeedback"
            element={<Layout component={<AddFeedback />} />}
          />
          <Route path="/all/:type" element={<ViewAll />} />
          <Route path="selectAddress" element={<SelectAddress />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
