import React, {useState , useEffect} from "react";
import logo from "../../Assets/logo-wide.png";
import person from "../../Assets/icons8-person-24.png";
import help from "../../Assets/icons8-help-50.png";
import cart from "../../Assets/icons8-favorite-cart-48.png";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Login from "../../Pages/Login";
import Search from "../../Pages/Search";
import { LocationOn, Person2Rounded, ShoppingCart } from "@mui/icons-material";
import { searchApi, user ,cartGetApi} from '../../utils/constant';
import qs from 'qs';
import { ToastContainer , toast } from "react-toastify";
import  CartBadge from "../CartBadge";
import { Divider } from "@mui/material";
import Filter from "../../Pages/Filter";
import { Avatar } from "flowbite-react";
import Signup from "../../Pages/Signup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Header = ({response}) => {
  const [searchingApi, setSearchingApi] = useState([])
  const [searchingValue, setSearchingValue] = useState()
  const userAddress = JSON.parse(localStorage.getItem("userAddress"));
  const userAddressLatLong = JSON.parse(localStorage.getItem("userAddressLatLong"));
  
  const navigate = useNavigate();


  const Addresses = () => {
    navigate("/Savedaddress/header");
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  // searchApi api calling
  const searchApi_f = (searchingValue) => {
    if (!searchingValue) {
      return
    }
    searchApi(qs.stringify({ userlat: userAddressLatLong?.lat, userlng: userAddressLatLong?.lng, serach_keyword: searchingValue }))
      .then((response) => {
        setSearchingApi(response?.data?.searchList)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (value) => {
    setSearchingValue(value)
    if (!value) {
      setSearchingApi([])
    }
  }
  useEffect(() => {
    searchApi_f(searchingValue)
  }, [searchingValue]);

 
  return (
    <>
      <nav class="lg:block hidden bg-white border-gray-200 dark:bg-gray-900 sticky top-0 z-50 shadow-md">
        <div class="max-w-screen-xl flex flex-wrap lg:gap-10 justify-center mx-auto lg:py-2 ">


        <span href="https://flowbite.com/" class="flex items-center lg:py-0 py-2">
            <img src={logo} class="h-8" alt="Flowbite Logo" onClick={()=>navigate("/")}/>
          </span>
      
    
         <div>
         {userAddress ? 
           <p className='py-1 pt-4 text-base font-semibold text-gray-600' onClick={(e) => { navigate('/selectAddress') }}><LocationOn /> {userAddress[userAddress?.length -2]} {userAddress[userAddress?.length-1]} </p>
           :
           <p className='py-1 pt-4 text-base font-semibold text-gray-600' onClick={(e) => { navigate('/selectAddress') }}><LocationOn /> Select location </p>
           }
      
           </div>
        
          <div class="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul class="font-medium flex flex-col  md:p-0  border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <span
                  href="#"
                  class="  text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500 flex gap-1"
                  aria-current="page"
                >
                  <Search/>
                  {/* <Filter/> */}
                </span>
              </li>
              <li>
                <span
                  href="#"
                  class=" py-2 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent flex gap-1"
                >
                  <div>
                <Login/>
                  </div>
                 
                </span>
              </li>
              

              <li onClick={() =>{localStorage.getItem("userTokenOfBrbun")?(navigate("/help")):toast("! login required")}}>
                <span
                  href="#"
                  class="!pt-3  text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent flex gap-1"
                >
                  <img src={help} className="w-6 h-6" alt="" />
                  <p className="">Help</p>
                </span>
              </li>
              
            
              <li onClick={() =>{localStorage.getItem("userTokenOfBrbun")?(navigate("/checkout")):toast("! login required")}}>
              
                <span class="!pt-3 py-2  text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent flex gap-1">
                 <CartBadge response={response}/>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
       <div className="lg:hidden">
       <nav class="bg-white text-center w-full border-gray-200 dark:bg-gray-900 fixed top-0 z-50 shadow-md">
        <div class="">
      <div>
      {userAddress ? 
          <p className='py-1 pt-4 text-base font-semibold text-gray-800' onClick={(e) => { navigate('selectAddress') }}><LocationOn /> {userAddress[userAddress?.length -2]} {userAddress[userAddress?.length-1]} </p>
          :
          <p className='py-1 pt-4 text-base font-semibold text-gray-800' onClick={(e) => { navigate('selectAddress') }}><LocationOn /> Select location </p>
          }
      </div>
      <Divider/>
     
        <div className="flex  justify-between py-2 ">
        <p href="https://flowbite.com/" class=" pr-20 px-2 lg:py-0 py-2">
            <img src={logo} class="h-8" alt="Flowbite Logo" onClick={()=>navigate("/")}/>
          </p>
         <span
          href="#"
          class="!pt-3  text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent flex gap-1"
          onClick={() =>{localStorage.getItem("userTokenOfBrbun")?(navigate("/help")):toast("! login required")}}>
          <img src={help} className=" w-6 h-6" alt="" />
          <p className="">Help</p>
           </span>
            <span class="!pt-3 py-2"
               onClick={() =>{localStorage.getItem("userTokenOfBrbun")?(navigate("/checkout")):toast("! login required")}}>
                 <CartBadge/>
                </span>
             <Login />

        </div>
          </div>
       
      </nav>  
       </div>

    </>
  );
};

export default Header;
