import { useNavigate, NavLink, useParams, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { addAddressApi, updateAddressApi } from "../../utils/constant";
import Geocode from "react-geocode";
import {
  LocationCity,
  LocationCityRounded,
  LocationOn,
  MyLocation,
} from "@mui/icons-material";
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { List, MenuItem } from "@mui/material";
import classNames from "classnames";
import home from "../../Assets/icons8-home-24.png";
import office from "../../Assets/icons8-office-30.png";
import locate from "../../Assets/icons8-location-50.png";
import Autocomplete from "react-google-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddAddress = () => {
  const navigate = useNavigate();
  const type = useParams("type");
  const items = useLocation();
  const [latLong, setLatLong] = useState();
  const [updateFromSaveAdd, setUpdateFromSaveAdd] = useState([]);
  const [address2, setaddress2] = useState();
  const [address1, setaddress1] = useState(
    updateFromSaveAdd?.address1 ? updateFromSaveAdd?.address1 : ""
  );
  const [city2, setCity2] = useState();
  const [countryByMapOnClick, setcCountryByMapOnClick] = useState();
  const [stateByMapOnClick, setStateByMapOnClick] = useState();
  const [addAddress, setAddAddress] = useState([
    {
      custId: "",
      fullName: "",
      mobile: "",
      address2: updateFromSaveAdd?.address2 ? updateFromSaveAdd?.address2 : "",
      city: "",
      postcode: updateFromSaveAdd?.postcode ? updateFromSaveAdd?.postcode : "",
      addressType: updateFromSaveAdd?.latitude
        ? updateFromSaveAdd?.latitude
        : "",
      latitude: updateFromSaveAdd?.latitude ? updateFromSaveAdd?.latitude : "",
      longitude: updateFromSaveAdd?.longitude
        ? updateFromSaveAdd?.longitude
        : "",
    },
  ]);
  var user = JSON.parse(localStorage.getItem("userData"));

  const handleChange = (value) => {
    setaddress1(value);
  };
  const handleChangeforType = (type) => {
    setAddAddress({
      ...addAddress,
      addressType: type,
    });
  };

  // ####################### start

  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 21.36223,
    lng: 74.898978,
  });
  const [address, setAddress] = useState("");
  const libraries = ["places"];
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setSelectedLocation(latLng);
  };

  const apiKey = "AIzaSyAO3TRgzZx5X3i6tTcuNMjCvvCxhWe4M3g";

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) =>
      setSelectedLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    );
  };
  // ####################### end

  Geocode.setApiKey("AIzaSyAO3TRgzZx5X3i6tTcuNMjCvvCxhWe4M3g");
  Geocode.setLanguage("en");

  const position = async () => {
    Geocode.fromLatLng(selectedLocation?.lat, selectedLocation.lng).then(
      (response) => {
        const address = response?.results[0]?.formatted_address;
        const myArray = address.split(",");
        if (myArray[2]) {
          setCity2(myArray[1]);
        } else if (myArray[1]) {
          setCity2(myArray[1]);
        }
        setaddress2(myArray.toString());
        setStateByMapOnClick(myArray[myArray?.length - 2]);
        setcCountryByMapOnClick(myArray[myArray?.length - 1]);
        if (myArray?.length == 2) {
          setCity2(myArray[0]);
          setAddAddress({
            ...addAddress,
            address2: myArray[myArray?.length - 2],
          });
        }
        console.log(
          address,
          "++++++++++++++++++++++++++++++++++++++++++++",
          myArray,
          myArray?.length,
          city2
        );
        var numberPattern = /\d+/;
        let pinCode = myArray[myArray?.length - 2].match(numberPattern);
        if (pinCode != null) {
          setAddAddress({
            ...addAddress,
            postcode: pinCode[0],
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };
  useEffect(() => {
    position();
  }, [selectedLocation]);
  useEffect(() => {
    if (items?.state) {
      setUpdateFromSaveAdd(items?.state);
    }
    console.log(items?.state?.address1, "666666666666666666666666666666");
  }, [items]);
  // addAddress api calling
  const addAddress_f = () => {
    if (!address1) {
      toast.error("Please enter Flat number, Landmark, Apartment", {
        toastId: "9",
      });
      return;
    }
    if (!addAddress?.postcode) {
      toast.error(
        "Please choose another location in this location not found pincode!",
        { toastId: "10" }
      );
      return;
    }
    if (!addAddress?.addressType) {
      toast.error("Please select address type", { toastId: "11" });
      return;
    }
    // console.log(addAddress);
    addAddressApi(
      qs.stringify({
        custId: user?.cust_id,
        fullName: user?.user_name,
        mobile: user?.mob_no,
        address1: address1,
        address2: address2 ? address2 : addAddress?.address2,
        city: city2,
        postcode: addAddress?.postcode,
        addressType: addAddress?.addressType,
        latitude: selectedLocation?.lat,
        longitude: selectedLocation?.lng,
      })
    )
      .then((response) => {
        if (response?.data?.status == 1) {
          toast.success(response?.data?.message, { toastId: "6" });
          navigate("/Savedaddress/checkout");
        }
        if (response?.data?.status == 0 || response?.data?.status == 2) {
          toast.error(response?.data?.message, { toastId: "5" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // here we are handling updateAddress api
  const updateAddress_f = (updateFromSaveAdd) => {
    if (!updateFromSaveAdd) {
      return;
    }
    // console.log(updateAddress);
    updateAddressApi(
      qs.stringify({
        custId: user?.cust_id,
        fullName: user?.user_name,
        mobile: user?.mob_no,
        address1: address1,
        address2: address2 ? address2 : addAddress?.address2,
        city: city2,
        postcode: addAddress?.postcode,
        addressType: addAddress?.addressType,
        latitude: selectedLocation?.lat,
        longitude: selectedLocation?.lng,
        addressId: updateFromSaveAdd,
      })
    )
      .then((response) => {
        if (response?.data?.status == 1) {
          toast.success(response?.data?.message, { toastId: "6" });
          navigate(-1);
        }
        if (response?.data?.status == 0 || response?.data?.status == 2) {
          toast.error(response?.data?.message, { toastId: "5" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(address, "=====================");
  return (
    <>
      <div>
        <ToastContainer />

        <div>
          <div className=" lg:flex-row lg:py-0 py-32 flex flex-col gap-5">
            <div className=" w-full">
              <div className="bg-white">
                <div className="lg:p-1 p-3">
                  <div className="p-3">
                    <span className="flex h-0 justify-end">
                      <MyLocation
                        className="relative top-36 right-[0.6rem] shadow z-50 cursor-pointer !text-gray-500 hover:!text-gray-700 bg-white p-2 !text-[2.5rem] rounded-[2px]"
                        onClick={getLocation}
                      />
                    </span>

                    <LoadScript libraries={libraries} googleMapsApiKey={apiKey}>
                      <StandaloneSearchBox>
                        <PlacesAutocomplete
                          value={address}
                          onChange={setAddress}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="py-2">
                              <span className="flex lg:flex-row justify-center flex-col gap-1 ">
                                <input
                                  {...getInputProps({
                                    placeholder: "Search...",
                                  })}
                                  className="outline-none border-red-800 p-1.5 border-2 rounded px-5 w-full"
                                />
                              </span>

                              <List
                                className={classNames(
                                  "!z-50 p-5  !fixed shadow !rounded",
                                  address !== 0 ? "block" : "hidden"
                                )}
                              >
                                {loading ? <div>Loading...</div> : null}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#ebebeb"
                                      : "#fff",
                                  };
                                  return (
                                    <MenuItem
                                      key={suggestion.placeId}
                                      onClick={() =>
                                        console.log(suggestion.description)
                                      }
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </MenuItem>
                                  );
                                })}
                              </List>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </StandaloneSearchBox>
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={selectedLocation}
                        zoom={10}
                        onClick={(event) => {
                          setSelectedLocation({
                            lat: event.latLng.lat(),
                            lng: event.latLng.lng(),
                          });
                        }}
                      >
                        <Marker position={selectedLocation} />
                      </GoogleMap>
                    </LoadScript>

                    <div className="pt-6">
                      <p className="py-1 text-base font-semibold text-gray-600">
                        <LocationOn />
                        {city2} ,{stateByMapOnClick} ,{countryByMapOnClick}
                      </p>
                      <p className="py-1 text-base font-semibold text-gray-600">
                        Flat number, Landmark, Apartment
                      </p>
                      <input
                        type="text"
                        value={address1}
                        onChange={(e) => handleChange(e.target.value)}
                        placeholder="Flat number, Landmark, Apartment"
                        className="p-2 w-full my-1 border border-gray-300 outline-none"
                      />
                      <p className="py-1 text-base font-semibold text-gray-500">
                        Save Address As :
                      </p>
                      <div className="flex justify-around pt-2">
                        <button
                          className="p-2 px-5 bg-gray-200 text-sm font-semibold rounded-2xl flex gap-1 items-center"
                          style={
                            addAddress?.addressType == "Home"
                              ? {
                                  backgroundColor: "rgb(74 222 128)",
                                  color: "white",
                                }
                              : null
                          }
                          onClick={(e) => handleChangeforType("Home")}
                        >
                          <img src={home} className="w-4 h-4" />
                          <p>Home</p>
                        </button>
                        <button
                          className="p-2 px-5 bg-gray-200 text-sm font-semibold rounded-2xl flex gap-1 items-center"
                          style={
                            addAddress?.addressType == "Office"
                              ? {
                                  backgroundColor: "rgb(74 222 128)",
                                  color: "white",
                                }
                              : null
                          }
                          onClick={(e) => handleChangeforType("Office")}
                        >
                          <img src={office} className="w-4 h-4" />
                          <p>Office</p>
                        </button>
                        <button
                          className="p-2 px-5 bg-gray-200 text-sm font-semibold rounded-2xl flex gap-1 items-center"
                          style={
                            addAddress?.addressType == "Others"
                              ? {
                                  backgroundColor: "rgb(74 222 128)",
                                  color: "white",
                                }
                              : null
                          }
                          onClick={(e) => handleChangeforType("Others")}
                        >
                          <img src={locate} className="w-4 h-4" />
                          <p>Others</p>
                        </button>
                      </div>
                      <div className="flex justify-center items-center mt-4 mx-3">
                        <button
                          className="font-semibold text-base text-white px-3 p-2 bg-green-400 w-full rounded-lg"
                          onClick={(e) => {
                            addAddress_f();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='text-lg font-bold text-gray-500 mt-2 bg-gray-100 p-4 px-8'><p>Payment</p></div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAddress;
