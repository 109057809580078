import React, { useEffect, useState } from "react";
import { useNavigate, NavLink, useParams } from 'react-router-dom';
import axios from "axios";
import { orderHistoryApi, logosImage, restaurantImage, orderDetailsApi, user } from '../../../utils/constant';
import qs from 'qs';

const Order = () => {
  const navigate = useNavigate()
  const order_id = useParams();
  const [detail, setDetail] = useState([]);

  const OrderDetail_f = () => {

    orderDetailsApi(qs.stringify({ custId: user?.cust_id , orderId :order_id.order_id }))
      .then((response) => {
        setDetail(response?.data?.orderDetails);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    OrderDetail_f();
  }, [])

  console.log(order_id.order_id)
  return (
    <>

      <div className="py-5">
        <div className="px-3 lg:px-40 lg:py-10 py-32 py-3 ">
         

          <div className=" p-4">
            
                <div className="p-2 border  h-fit rounded-md w-full hover:shadow-lg bg-gray-50">

                  <div className="flex justify-center gap-40">
                    <img src={detail?.restaurant_image != "" && detail?.restaurant_image != "NA" ? `${restaurantImage}${detail?.restaurant_image}` : logosImage} className='w-12 h-12 rounded-md' />

                 
                      <p className="text-2xl  pt-2 font-semibold"> {detail.restaurant_name}</p>

                        <p className="pt-2  text-xl font-bold text-green-800">{detail?.restaurant_order_status}</p>
                      
                   


                  </div>

                  <div className="border-b border-gray-300 py-1"></div>
                  
                  <div className='p-4 '>
                    <div><p className='font-semibold '>ORDER NUMBER</p>
                      <p className=' pb-2 text-gray-600'>{detail?.order_id}</p></div>
                      
                    <div><p className='font-semibold '>Mobile Number</p>
                      <p className='pb-2 text-gray-600'>{detail?.customer_contact_no}</p></div>

                      <div><p className='font-semibold '>ORDER DATE</p>
                      <p className='pb-2 text-gray-600'>{detail?.date_added}</p></div>
                  
                  
                      <div><p className='font-semibold '>Distance</p>
                      <p className=' pb-2 text-gray-600'>{detail?.total_distance} KM away</p></div>
                 
                    <div><p className='font-semibold '>TOTAL AMOUNT</p>
                      <p className='pb-2 text-gray-600'>{detail?.total_amount}</p></div>
                   
                    <div><p className='font-semibold'>Payment Mode </p>
                    <p className='pb-2 text-gray-600'>Paid : {detail?.payment_type}</p>  </div>
                  
                    <div><p className='font-semibold '>ITEMS</p>
                    <p className=' pb-2 text-gray-600'> {detail?.food_name}</p>
                     </div>
                     <div><p className='font-semibold '>Deliver To</p>
                    <p className=' pb-2 text-gray-600'> {detail?.delivery_address}</p>
                     </div>
                      </div>

                      <p className=' pb-2 text-gray-600'></p>

                </div>
             
         
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
