import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

export default function Playground() {
    const navigate = useNavigate()
  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };
  const flatProps = {
    options: top100Films.map((option) => option.title),
  };
  const [value, setValue] = React.useState(null);

  return (
    <Stack spacing={5} sx={{ width: 400 }}>
      <Autocomplete
        {...defaultProps}
        id="Search Resturants"
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField {...params} label="Search Resturants" variant="standard"  onClick={()=>navigate("/order")}/>
        )}
      />
      </Stack>
  );
}

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 }
];
