import React, { useState, useEffect } from "react";
import { ShoppingCart } from "@mui/icons-material";
import { user, cartGetApi, cartAddApi } from "../../utils/constant";
import qs from "qs";
import { ToastContainer, toast } from "react-toastify";
import Badge from "@mui/material/Badge";

const CartBadge = ({ }) => {
  const [carttotal, setCarttotal] = useState([]);
  const Getcart_f = () => {
    cartGetApi(qs.stringify({ custId: user?.cust_id, coupon_id: "None" }))
      .then((response) => {
        setCarttotal(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
     
  };
  

  // addToCartApi api calling
  const addToCartApi_f = (
    i,
    qty1,
    cPrice,
    foodMenuId,
    foodMenuName,
    sAdminPrice,
    MListid,
    restaurantId,
    userId,
    priceTot,
    qtyTot
  ) => {
    cartAddApi({
      foodMenuId: foodMenuId,
      qty: qty1,
      menuExtraItemId: [
        {
          extra_customer_price: cPrice,
          extra_item_name: foodMenuName,
          extra_super_admin_price: sAdminPrice,
          id: MListid,
          is_checked: "0",
        },
      ],
      menuExtraItemType: 0,
      totalPrice: cPrice,
      custId: user?.cust_id ? user?.cust_id : userId,
      restaurantId: restaurantId,
      variationItemType: 1,
      variationExtraItemId: [
        {
          extra_customer_price: cPrice,
          extra_item_name: foodMenuName,
          extra_super_admin_price: sAdminPrice,
          id: MListid,
        },
      ],
    })
      .then((response) => {
        console.log("+++++++++++++++", response?.data?.message);
        if (response?.data?.status == 1) {
          toast.success(response?.data?.message, { toastId: "6" });
        }
        if (response?.data?.status == 0 || response?.data?.status == 2) {
          toast.error(response?.data?.message, { toastId: "5" });
        }
        Getcart_f();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    Getcart_f();
  }, []);

  return (
    <>
      <div className="">
        <Badge badgeContent={carttotal?.grandTotalQty} color="primary">
          <ShoppingCart />
        </Badge>
      </div>
    </>
  );
};
export default CartBadge;
