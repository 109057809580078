import { useNavigate, NavLink, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import {
  user,
  deleteAddressApi,
  defaultAddressApi,
} from "../../utils/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SavedAddresses = () => {
  const navigate = useNavigate();
  const type = useParams("type");
  const [getAddress, setGetAddress] = useState([]);

  const update_f = (addId) => {
    navigate("/addaddress/update", { state: addId });
  };
  const navigateTocheckout_f = (e, i) => {
    let add = getAddress[i];
    navigate("/checkout", { state: { add } });
  };
  const addAddress_f = (e) => {
    navigate("/addaddress");
  };
  // getAddress api calling
  const getAddress_f = () => {
    axios
      .post(
        `https://brbun.in/super_admin/brbun_web_services/brbun_customer/getAddress.php`,
        qs.stringify({
          restaurant_lat: "21.321705",
          restaurant_lng: "74.891239",
          custId: user?.cust_id,
        })
      )
      .then((response) => {
        setGetAddress(response?.data?.addressList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // deleteAddressApi api calling
  const deleteAddressApi_f = (addId) => {
    if (!user?.cust_id) {
      return;
    }
    deleteAddressApi(qs.stringify({ custId: user?.cust_id, addressId: addId }))
      .then((response) => {
        if (response?.data?.status == 1) {
          toast.success(response?.data?.message, { toastId: "6" });
          getAddress_f();
        }
        if (response?.data?.status == 0 || response?.data?.status == 2) {
          toast.error(response?.data?.message, { toastId: "5" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // defaultAddressApi api calling
  const defaultAddressApi_f = (addId) => {
    if (!user?.cust_id) {
      return;
    }
    defaultAddressApi(qs.stringify({ custId: user?.cust_id, addressId: addId }))
      .then((response) => {
        if (response?.data?.status == 1) {
          toast.success(response?.data?.message, { toastId: "6" });
          getAddress_f();
        }
        if (response?.data?.status == 0 || response?.data?.status == 2) {
          toast.error(response?.data?.message, { toastId: "5" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAddress_f();
  }, []);
  return (
    <>
      <ToastContainer />

      <p className="text-5xl bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 text-center py-4 pt-10 font-bold">
        Choose a address
      </p>
      <div className="lg:px-20 px-0 lg:py-10 py-3 lg:flex-row flex flex-col lg:gap-16 gap-2">
        <div className="grid lg:grid-cols-3 grid--cols-1 gap-3 px-4">
          {getAddress &&
            getAddress?.map((address, i) => (
              <div className="border rounded p-4 h-fit">
                <p className="text-lg font-semibold py-1">Other</p>
                {/* <p className='text-gray-500 py-1'>Block C, 19/23, indiranagar Lucknow Uttar Pradesh</p> */}
                {/* {type?.type != "checkout" ?
                  <>
                    <p className='text-gray-500 py-1'>{address?.address1}, {address?.address2}, {address?.city} {address?.postcode} {address?.address_default == 1 ? "Default" : ""}</p>
                    <div className='flex justify-between'><button onClick={(e) => { update_f(address) }} className='text-orange-500 font-semibold cursor-pointer'>Update</button><button onClick={(e) => { deleteAddressApi_f(address?.address_id) }} className=' font-semibold cursor-pointer'>Delete</button><button onClick={(e) => { defaultAddressApi_f(address?.address_id) }} className='font-semibold cursor-pointer'>Set default</button></div>
                  </>
                  :
                  <>
                    <p className='text-gray-500 py-1' onClick={(e) => { navigateTocheckout_f(e, i) }} >{address?.address1}, {address?.address2}, {address?.city} {address?.postcode}</p>
                    <h4>{address?.address_default == 1 ? "Default" : ""}</h4>
                  </>
                } */}
                <p
                  className="text-gray-500 py-1"
                  onClick={(e) => {
                    navigateTocheckout_f(e, i);
                  }}
                >
                  {address?.address1}, {address?.address2}, {address?.city}{" "}
                  {address?.postcode}{" "}
                  {address?.address_default == 1 ? "Default" : ""}
                </p>
                <div className="flex justify-between">
                  <button
                    onClick={(e) => {
                      deleteAddressApi_f(address?.address_id);
                    }}
                    className=" !bg-gradient-to-r from-red-700 to-orange-600 font-semibold cursor-pointer text-white p-1 rounded"
                  >
                    Delete
                  </button>
                  <button
                    onClick={(e) => {
                      defaultAddressApi_f(address?.address_id);
                    }}
                    className="font-semibold cursor-pointer"
                  ></button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="flex justify-center font-semibold p-2 !bg-gradient-to-r from-red-700 to-orange-600 text-base text-gray-500 border-t">
        <p
          className="text-xl font-bold text-white "
          onClick={(e) => {
            addAddress_f(e);
          }}
        >
          + Add New
        </p>
      </div>
    </>
  );
};
{
  /* <button onClick={(e) => { update_f(address) }} className='text-orange-500 font-semibold cursor-pointer'>Update</button> */
}
export default SavedAddresses;
