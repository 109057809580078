import React, { useEffect, useState } from "react";
import upi from "../../Assets/icons8-bhim-upi-50.png";
import card from "../../Assets/icons8-debit-card-50.png";
import wallet from "../../Assets/icons8-wallet-30.png";
import banking from "../../Assets/icons8-banking-24.png";
import item1 from "../../Assets/e437cba7f2def94d3e067ba1a3e592bc.webp";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import axios from "axios";
import {
  orderHistoryApi,
  logosImage,
  restaurantImage,
  user,
  cancelOrderApi,
} from "../../utils/constant";
import qs from "qs";
import { toast } from "react-toastify";

const Order = () => {
  const navigate = useNavigate();
  const id = useParams("id");
  const [detail, setDetail] = useState([]);
  const [select, setProduct] = useState([]);

  const OrderDetail_f = () => {
    orderHistoryApi(qs.stringify({ custId: user?.cust_id }))
      .then((response) => {
        setDetail(response.data.orderHistoryList);
        setProduct(response.data.orderHistoryList.selected_product);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cancelOrder_f = (order_id, orderStatus) => {
    cancelOrderApi(
      qs.stringify({ orderId: order_id, orderStatus: orderStatus })
    )
      .then((response) => {
        console.log(response.data.message);
        if (response?.data?.status == 1) {
          toast.success(response?.data?.message, { toastId: "6" });
        }
        if (response?.data?.status == 0 || response?.data?.status == 2) {
          toast.error(response?.data?.message, { toastId: "5" });
        }
        OrderDetail_f();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    OrderDetail_f();
  }, []);
  const userLogOutApi_f = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <div className="py-5">
        <div className="lg:px-20 px-3 lg:py-10 py-32 py-3 lg:flex-row flex flex-col justify-center lg:gap-16 gap-3">
          <div className="lg:w-1/4 bg-[#edf1f7] h-fit pl-2 py-2">
            <div className="flex gap-3 p-4 ml-2 mt-2 bg-white ">
              <img src={upi} className="w-8 h-8" alt="" />
              <p className="text-xl font-semibold text-gray-600">My Orders</p>
            </div>
            <div
              className="flex gap-3 p-4 ml-2 "
              onClick={() => navigate("/profile")}
            >
              <img src={banking} className="w-8 h-8" alt="" />
              <p className="text-xl font-semibold text-gray-600">My Profile</p>
            </div>
            <div
              className="flex gap-3 p-4 ml-2 "
              onClick={() => navigate("/addfeedback")}
            >
              <img src={banking} className="w-8 h-8" alt="" />
              <p className="text-xl font-semibold text-gray-600">Add Feeback</p>
            </div>
            <div className="flex gap-3 p-4 ml-2 mb-2 ">
              <img src={card} className="w-8 h-8" alt="" />
              <p
                className="text-xl font-semibold text-gray-600"
                onClick={(e) => userLogOutApi_f(e)}
              >
                Logout
              </p>
            </div>
          </div>

          <div className="grid lg:w-2/3 md:grid-cols-2 overflow-y-scroll max-h-80vh grid-cols-1 lg:gap-10 gap-5">
            {detail?.map((item) => {
              return (
                <>
                  <div className="p-2 border  h-fit rounded-md w-full hover:shadow-lg bg-gray-50">
                    <div className="flex gap-2">
                      <img
                        src={
                          item?.restaurant_image != "" &&
                          item?.restaurant_image != "NA"
                            ? `${restaurantImage}${item?.restaurant_image}`
                            : logosImage
                        }
                        className="w-12 h-12 rounded-md"
                        onClick={() =>
                          navigate(`/orderdetail/${item?.order_id}`)
                        }
                      />

                      <div className="flex flex-col">
                        <p className="text-lg font-semibold">
                          {" "}
                          {item.restaurant_name}
                        </p>

                        <div>
                          <button
                            className="text-bold text-green-800"
                            onClick={() =>
                              cancelOrder_f(
                                item?.order_id,
                                item?.restaurant_order_status
                              )
                            }
                          >
                            {item?.restaurant_order_status}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="border-b border-gray-300 py-1"></div>

                    <div className="p-2 grid grid-cols-2 gap-2 ">
                      <div>
                        <p className="font-semibold text-sm">ORDER NUMBER</p>
                        <p className="text-xs pb-2 text-gray-600">
                          {item?.order_id}
                        </p>
                      </div>

                      <div>
                        <p className="font-semibold text-sm">ORDER DATE</p>
                        <p className="text-xs pb-2 text-gray-600">
                          {item?.date_added.slice(0, 10)}
                        </p>
                      </div>

                      <div>
                        <p className="font-semibold text-sm">Distance</p>
                        <p className="text-xs pb-2 text-gray-600">
                          {item?.total_distance} KM away
                        </p>
                      </div>
                      <div>
                        <p className="font-semibold text-sm">Delivery charge</p>
                        <p className="text-xs pb-2 text-gray-600">
                          {item?.delivery_charge}
                        </p>
                      </div>

                      <div>
                        <p className="font-semibold text-sm">TOTAL AMOUNT</p>
                        <p className="text-xs pb-2 text-gray-600">
                          {item?.total_amount}
                        </p>
                      </div>

                      <div>
                        <p className="font-semibold text-sm">Payment Mode </p>
                        <p className="text-xs pb-2 text-gray-600">
                          {item?.payment_type}
                        </p>{" "}
                      </div>

                      <div>
                        <p className="font-semibold text-sm">Distance</p>
                        <p className="text-xs pb-2 text-gray-600">
                          {item?.total_distance} KM away
                        </p>
                      </div>

                      <div>
                        <p className="font-semibold text-sm">ITEMS</p>
                      </div>
                      {item?.selected_product?.map((data) => {
                        return (
                          <div>
                            {" "}
                            <p className="text-xs  text-gray-600">
                              {data?.qty} X {data.food_menu_name}{" "}
                              <span className="text-xs  text-red-600">
                                {data?.extra_item_name}
                              </span>
                            </p>
                            <p className="text-sm  text-gray-600">
                              <span className="text-sm pb-2 text-gray-600">
                                {data?.qty}
                              </span>
                              X{data?.price}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
