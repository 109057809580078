import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import qs from "qs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Carousel } from "react-responsive-carousel";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  bannerSliderApi,
  quickDeliveryRestaurantsApi,
  nearbyRestaurantsApi,
  bestRatedRestaurantsApi,
  featuredAdvertiseRestaurantsApi,
  restaurantImage,
  sliderImage,
  restaurantCategoryApi,
  FoodCategoryImage,
  logosImage,
  cartGetApi,
  user,
  cartClearAllApi,
} from "../../utils/constant";
import { LocationOn } from "@mui/icons-material";

const HomePage = (props) => {
  const navigate = useNavigate();

  const userAddressLatLong = JSON.parse(
    localStorage.getItem("userAddressLatLong")
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [value, setValue] = React.useState("1");
  const Navigate = useNavigate();

  const Search = () => {
    Navigate("/search");
  };
  const [restaurantCategory, setRestaurantCategory] = useState([]);
  const [bannerSlider, setBannerSlider] = useState([]);
  const [quickDeliveryRestaurants, setQuickDeliveryRestaurants] = useState([]);
  const [featuredAdvertiseRestaurants, setFeaturedAdvertiseRestaurants] =
    useState([]);
  const [bestRatedRestaurants, setBestRatedRestaurants] = useState([]);
  const [nearbyRestaurants, setNearbyRestaurants] = useState([]);
  const [carttotal, setCarttotal] = useState([]);
  const userAddress = JSON.parse(localStorage.getItem("userAddress"));

  // restaurantCategory api calling
  const restaurantCategory_f = () => {
    restaurantCategoryApi(
      qs.stringify({
        userlat: userAddressLatLong?.lat,
        userlng: userAddressLatLong?.lng,
      })
    )
      .then((response) => {
        setRestaurantCategory(response?.data?.categoryList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // bannerSlider api calling
  const bannerSlider_f = () => {
    bannerSliderApi(
      qs.stringify({
        userlat: userAddressLatLong?.lat,
        userlng: userAddressLatLong?.lng,
      })
    )
      .then((response) => {
        setBannerSlider(response?.data?.sliderList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // quickDeliveryRestaurants api calling
  const quickDeliveryRestaurants_f = () => {
    quickDeliveryRestaurantsApi(
      qs.stringify({
        userlat: userAddressLatLong?.lat,
        userlng: userAddressLatLong?.lng,
      })
    )
      .then((response) => {
        setQuickDeliveryRestaurants(response?.data?.restaurantList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // featuredAdvertiseRestaurants api calling
  const featuredAdvertiseRestaurants_f = () => {
    featuredAdvertiseRestaurantsApi(
      qs.stringify({
        userlat: userAddressLatLong?.lat,
        userlng: userAddressLatLong?.lng,
      })
    )
      .then((response) => {
        setFeaturedAdvertiseRestaurants(response?.data?.restaurantList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // BestRatedRestaurants api calling
  const BestRatedRestaurants_f = () => {
    bestRatedRestaurantsApi(
      qs.stringify({
        userlat: userAddressLatLong?.lat,
        userlng: userAddressLatLong?.lng,
      })
    )
      .then((response) => {
        setBestRatedRestaurants(response?.data?.restaurantList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // NearbyRestaurants api calling
  const NearbyRestaurants_f = () => {
    nearbyRestaurantsApi(
      qs.stringify({
        userlat: userAddressLatLong?.lat,
        userlng: userAddressLatLong?.lng,
      })
    )
      .then((response) => {
        setNearbyRestaurants(response?.data?.restaurantList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // restaurantCateName
  const restaurantCateName_f = (id) => {
    Navigate(`/Category/${id}`);
  };
  const Getcart_f = () => {
    cartGetApi(qs.stringify({ custId: user?.cust_id, coupon_id: "None" }))
      .then((response) => {
        setCarttotal(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const cartClearAllApi_f = () => {
    cartClearAllApi(qs.stringify({ custId: user?.cust_id, coupon_id: "None" }))
      .then((response) => {
        Getcart_f();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Item = (id) => {
    console.log(!carttotal?.restaurant_id);
    if (!carttotal?.restaurant_id) {
      Navigate(`/items/${id}`);
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want cleart cart",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          cartClearAllApi_f();
        }
      });
    }
  };
  useEffect(() => {
    restaurantCategory_f();
    bannerSlider_f();
    quickDeliveryRestaurants_f();
    featuredAdvertiseRestaurants_f();
    BestRatedRestaurants_f();
    NearbyRestaurants_f();
    Getcart_f();
  }, []);
  console.log(userAddressLatLong, "llll");

  return (
    <>
      {userAddressLatLong === null ? (
        <div>
          <p className="text-center lg:text-2xl text-xl lg:mt-4 mt-32 my-4">
            Please Choose a Location
          </p>
          <div className="flex justify-center ">
            <img
              src="https://cdn-icons-png.flaticon.com/512/8226/8226227.png"
              alt=""
              className="lg:h-96 h-56"
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="lg:block hidden bg-[#171a29] flex  justify-center items-center lg:p-5 p-2 relative">
            <div className="flex overflow-scroll  lg:gap-5 gap-2 justify-center items-center">
              {bannerSlider &&
                bannerSlider?.map((bannerSlid) => (
                  <img
                    src={`https://brbun.in/super_admin/assets/uploads/slider_images/${bannerSlid?.banner_image}`}
                    className="lg:w-48  h-32 rounded"
                  />
                ))}
            </div>
          </div>
          <div className="lg:hidden pt-32 p-3 bg-[#171a29]">
            <Carousel autoPlay={true} showStatus={false} showThumbs={false}>
              {bannerSlider &&
                bannerSlider?.map((bannerSlid) => (
                  <img
                    src={`https://brbun.in/super_admin/assets/uploads/slider_images/${bannerSlid?.banner_image}`}
                    className="h-[150px]  w-full"
                  />
                ))}
            </Carousel>
          </div>
          <div className="lg:hidden w-9/10 flex  gap-2  pt-3 px-2  md:grid md:grid-cols-8 md:gap-4 sm:grid sm:grid-cols-6 sm:gap-4 grid grid-cols-3 gap-2 m-auto">
            {restaurantCategory &&
              restaurantCategory?.map((restaurantCat) => (
                <>
                  <div className="flex flex-col">
                    <div className="">
                      <img
                        onClick={(e) => {
                          restaurantCateName_f(restaurantCat?.food_category_id);
                        }}
                        src={`${FoodCategoryImage}${restaurantCat?.food_category_image}`}
                        className="!w-20 h-20 border rounded-full m-auto object-cover shadow-xl shadow-gray-700"
                      />
                    </div>
                    <div className="w-2/4 m-auto mt-1">
                      {restaurantCat?.food_category_name.length > 15
                        ? restaurantCat?.food_category_name.slice(0, 9)
                        : restaurantCat?.food_category_name}
                    </div>
                  </div>
                </>
              ))}
          </div>

          <div className="lg:block hidden flex gap-2 py-4 pt-3 px-2 justify-center items-center bg-white lg:grid lg:grid-cols-8 lg:gap-4 m-auto">
            {restaurantCategory &&
              restaurantCategory?.map((restaurantCat) => (
                <>
                  <div className="flex flex-col">
                    <div className="">
                      <img
                        onClick={(e) => {
                          restaurantCateName_f(restaurantCat?.food_category_id);
                        }}
                        src={`${FoodCategoryImage}${restaurantCat?.food_category_image}`}
                        className="!w-24 h-24 border rounded-full m-auto object-cover shadow-xl shadow-gray-700"
                      />
                    </div>
                    <div className="w-fit m-auto mt-2">
                      {restaurantCat?.food_category_name.length > 15
                        ? restaurantCat?.food_category_name.slice(0, 12) + "..."
                        : restaurantCat?.food_category_name}
                    </div>
                  </div>
                </>
              ))}
          </div>

          <Box sx={{ width: "100%" }} className="lg:block hidden ">
            <TabContext value={value}>
              <Box className="!py-5 !px-20  ">
                <TabList
                  className="border-b py-2"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Quick Delivery"
                    className="!mx-3 !text-white !font-black !rounded bg-gradient-to-r from-red-700 to-orange-500 "
                    value="1"
                  />
                  <Tab
                    label="Featured/Advertised"
                    value="2"
                    className="!mx-3 !text-white !font-black !rounded !bg-red-800 bg-gradient-to-r from-red-700 to-orange-500 "
                  />
                  <Tab
                    label="Best Rated Restaurants"
                    value="3"
                    className="!mx-3 !text-white !font-black !rounded !bg-red-800 bg-gradient-to-r from-red-700 to-orange-500 "
                  />
                  <Tab
                    label="Near Restaurants"
                    value="4"
                    className="!mx-3 !text-white !font-black !rounded !bg-red-800 bg-gradient-to-r from-red-700  to-orange-500 "
                  />
                </TabList>
              </Box>

              <TabPanel value="1">
                <div className="flex justify-between  lg:px-20 px-4">
                  <div className="lg:text-2xl text-xl font-bold text-gray-600">
                    <p className="bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 font-bold">
                      Quick Delivery
                    </p>
                  </div>
                  <div className="lg:text-2xl text-xl font-bold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                    <NavLink to={`all/quickDeliveryRestaurants`}>
                      View all
                    </NavLink>
                  </div>
                </div>

                <div className="lg:p-10 lg:px-20 p-3 px-3 grid gap-5 lg:grid-cols-4">
                  {quickDeliveryRestaurants &&
                    quickDeliveryRestaurants?.map((restaurant) => (
                      <div
                        className="flex flex-col p-4 justify-center border border-gray-200 shadow-xl rounded hover:scale-110 hover:duration-300 delay-75"
                        onClick={(e) => {
                          localStorage.getItem("userTokenOfBrbun")
                            ? Item(restaurant?.restaurant_id)
                            : toast("! login required");
                        }}
                      >
                        <div className="h-58 w-58">
                          <img
                            className="w-58 h-48 m-auto"
                            src={`${restaurantImage}${restaurant?.restaurant_image}`}
                          />
                        </div>
                        <p className="font-bold text-lg bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 m-auto">
                          {restaurant?.restaurant_name}
                        </p>
                      </div>
                    ))}
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="flex justify-between  lg:px-20 px-3">
                  <div className="lg:text-2xl text-xl font-bold text-gray-600">
                    <p className="bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                      Featured/Advertised
                    </p>
                  </div>
                  <div className="lg:text-2xl text-xl font-bold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                    <NavLink to={`all/featuredAdvertiseRestaurants`}>
                      View all
                    </NavLink>
                  </div>
                </div>
                <div className="lg:p-10 p-3 lg:px-20 px-3 gap-5 grid lg:grid-cols-4 grid-cols-2">
                  {featuredAdvertiseRestaurants &&
                    featuredAdvertiseRestaurants?.map(
                      (featuredAdvertiseRestaurant) => (
                        <div
                          className="flex flex-col p-4 justify-center items-center border border-gray-200 shadow-xl rounded hover:scale-110 hover:duration-300 delay-75"
                          onClick={(e) => {
                            localStorage.getItem("userTokenOfBrbun")
                              ? Item(featuredAdvertiseRestaurant?.restaurant_id)
                              : toast("! login required");
                          }}
                        >
                          <div className="h-58 w-58">
                            <img
                              className="w-58 h-48 m-auto"
                              src={`${restaurantImage}${featuredAdvertiseRestaurant?.restaurant_image}`}
                            />
                          </div>
                          <div className="text-base font-semibold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 py-1">
                            <p className="font-bold text-xl m-auto">
                              {featuredAdvertiseRestaurant?.restaurant_name}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div className="flex justify-between lg:px-20 px-3">
                  <div className="lg:text-2xl text-xl font-bold text-gray-600">
                    <p className="bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                      Best Rated Restaurants
                    </p>
                  </div>
                  <div className="lg:text-2xl text-xl font-bold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                    <NavLink to={`all/bestRatedRestaurants`}>View all</NavLink>
                  </div>
                </div>
                <div className="lg:p-10 p-3 lg:px-20 px-3 grid gap-5 lg:grid-cols-4 grid-cols-2 ">
                  {bestRatedRestaurants &&
                    bestRatedRestaurants?.map((bestRatedRestaurant) => (
                      <div
                        className="flex flex-col p-4 justify-center border border-gray-200 shadow-xl  rounded hover:scale-110 hover:duration-300 delay-75 m-auto"
                        onClick={(e) => {
                          localStorage.getItem("userTokenOfBrbun")
                            ? Item(bestRatedRestaurant?.restaurant_id)
                            : toast("! login required");
                        }}
                      >
                        <div className="h-58 w-58">
                          <img
                            className="w-58 h-48 m-auto"
                            src={`${restaurantImage}${bestRatedRestaurant?.restaurant_image}`}
                          />
                        </div>
                        <div className="text-base font-semibold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 py-1">
                          <p className="font-bold text-xl m-auto">
                            {bestRatedRestaurant?.restaurant_name}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </TabPanel>
              <TabPanel value="4">
                <div className="flex justify-between lg:px-20 px-3">
                  <div className="lg:text-2xl text-xl font-bold text-gray-600">
                    <p className="bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                      Near Restaurants
                    </p>
                  </div>
                  <div className="lg:text-2xl text-xl font-bold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                    <NavLink to={`all/nearbyRestaurants`}>View all</NavLink>
                  </div>
                </div>
                <div className="lg:p-10 p-3 lg:px-20 px-3 grid gap-5 lg:grid-cols-4 grid-cols-2 ">
                  {nearbyRestaurants &&
                    nearbyRestaurants?.map((nearbyRestaurant) => (
                      <div
                        className="flex flex-col p-4 justify-center border border-gray-200 shadow-xl rounded hover:scale-110 hover:duration-300 delay-75"
                        onClick={(e) => {
                          localStorage.getItem("userTokenOfBrbun")
                            ? Item(nearbyRestaurant?.restaurant_id)
                            : toast("! login required");
                        }}
                      >
                        <div className="h-58 w-58">
                          <img
                            className="w-58 h-48 m-auto"
                            src={`${restaurantImage}${nearbyRestaurant?.restaurant_image}`}
                            alt=""
                          />
                        </div>
                        <div className="text-base font-semibold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 py-1">
                          <p className="font-bold text-xl m-auto">
                            {nearbyRestaurant?.restaurant_name}
                          </p>
                        </div>
                        <div className="text-sm font-light text-gray-600 py-1">
                          <p>{nearbyRestaurant?.food_category_name}</p>
                          <p className=" ">
                            {nearbyRestaurant?.restaurant_type}
                          </p>
                          <p>{nearbyRestaurant?.distance?.slice(0, 5)} KM</p>
                        </div>
                      </div>
                    ))}
                </div>
              </TabPanel>
            </TabContext>
          </Box>
          <div className="lg:block hidden">
            <Carousel autoPlay={true} showStatus={false} showThumbs={false}>
              {bannerSlider &&
                bannerSlider?.map((bannerSlid) => (
                  <img
                    src={`https://brbun.in/super_admin/assets/uploads/slider_images/${bannerSlid?.banner_image}`}
                    className="h-[500px] pb-5 w-full"
                  />
                ))}
            </Carousel>
          </div>

          <div className="lg:hidden">
            <Box sx={{ width: "100%" }} className="">
              <TabContext value={value}>
                <Box className="!py-1 ">
                  <TabList
                    v
                    className="border-b py-2 "
                    variant="scrollable"
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="Quick Delivery"
                      className="!text-xs !text-white !font-black !rounded !bg-gradient-to-r from-red-700  to-orange-500 !mx-4"
                      value="1"
                    />
                    <Tab
                      label="Featured/Advertised"
                      value="2"
                      className="!text-xs !text-white !font-black !rounded !bg-gradient-to-r from-red-700  to-orange-500 !mx-4"
                    />
                    <Tab
                      label="Best Rated Restaurants"
                      value="3"
                      className="!text-xs !text-white !font-black !rounded  !bg-gradient-to-r from-red-700  to-orange-500 !mx-4"
                    />
                    <Tab
                      label="Near Restaurants"
                      value="4"
                      className="!text-xs !text-white !font-black !rounded !bg-red-800 bg-gradient-to-r from-red-700 to-orange-500 !mx-4 "
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div className="flex pb-4 justify-between">
                    <div className="font-bold text-gray-600">
                      <p className="bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 font-bold">
                        Quick Delivery
                      </p>
                    </div>
                    <div className=" font-bold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                      <NavLink to={`all/quickDeliveryRestaurants`}>
                        View all
                      </NavLink>
                    </div>
                  </div>

                  <div className=" grid gap-2 grid-cols-1 md:grid-cols-3 sm:grid-cols-2">
                    {quickDeliveryRestaurants &&
                      quickDeliveryRestaurants?.map((restaurant) => (
                        <div
                          className="flex flex-col border border-gray-200 shadow-xl justify-center items-center p-1 rounded hover:scale-110 hover:duration-300 delay-75"
                          onClick={(e) => {
                            Item(restaurant?.restaurant_id);
                          }}
                        >
                          <div className="w-58 h-58">
                            <img
                              className="w-58 h-48 m-auto"
                              src={`${restaurantImage}${restaurant?.restaurant_image}`}
                            />
                          </div>
                          <div className="text-base font-semibold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 py-1 m-auto">
                            {restaurant?.restaurant_name}
                          </div>
                        </div>
                      ))}
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div className="flex pb-4 justify-between ">
                    <div className="font-bold text-gray-600">
                      <p className="bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 font-bold">
                        Featured
                      </p>
                    </div>
                    <div className=" font-bold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                      <NavLink to={`all/featuredAdvertiseRestaurants`}>
                        View all
                      </NavLink>
                    </div>
                  </div>

                  <div className=" grid gap-2 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 ">
                    {featuredAdvertiseRestaurants &&
                      featuredAdvertiseRestaurants?.map(
                        (featuredAdvertiseRestaurant) => (
                          <div
                            className="flex flex-col justify-center items-center p-1 border border-gray-200 shadow-xl rounded hover:scale-110 hover:duration-300 delay-75"
                            onClick={(e) => {
                              Item(featuredAdvertiseRestaurant?.restaurant_id);
                            }}
                          >
                            <div className="w-58 h-58">
                              <img
                                className="w-58 h-48 m-auto"
                                src={`${restaurantImage}${featuredAdvertiseRestaurant?.restaurant_image}`}
                              />
                            </div>
                            <div className="text-base font-semibold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 py-1 m-auto">
                              {featuredAdvertiseRestaurant?.restaurant_name}
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </TabPanel>
                <TabPanel value="3">
                  <div className="flex pb-4 justify-between ">
                    <div className="font-bold text-gray-600">
                      <p className="bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 font-bold">
                        Best Resturant
                      </p>
                    </div>
                    <div className=" font-bold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                      <NavLink to={`all/bestRatedRestaurants`}>
                        View all
                      </NavLink>
                    </div>
                  </div>
                  <div className="grid gap-2 grid-cols-1 md:grid-cols-3 sm:grid-cols-2">
                    {bestRatedRestaurants &&
                      bestRatedRestaurants?.map((bestRatedRestaurant) => (
                        <div
                          className="flex flex-col justify-center items-center p-1 border border-gray-200 shadow-xl rounded hover:scale-110 hover:duration-300 delay-75"
                          onClick={(e) => {
                            Item(bestRatedRestaurant?.restaurant_id);
                          }}
                        >
                          <div className="w-58 h-58">
                            <img
                              className="w-58 h-48 m-auto"
                              src={`${restaurantImage}${bestRatedRestaurant?.restaurant_image}`}
                            />
                          </div>
                          <div className="text-base font-semibold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 py-1 m-auto">
                            <p>{bestRatedRestaurant?.restaurant_name}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </TabPanel>
                <TabPanel value="4">
                  <div className="flex pb-4 justify-between ">
                    <div className="font-bold text-gray-600">
                      <p className="bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 font-bold">
                        NearbyResturant
                      </p>
                    </div>
                    <div className=" font-bold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600">
                      <NavLink to={`all/nearbyRestaurants`}>View all</NavLink>
                    </div>
                  </div>
                  <div className="grid gap-2 grid-cols-1 md:grid-cols-3 sm:grid-cols-2">
                    {nearbyRestaurants &&
                      nearbyRestaurants?.map((nearbyRestaurant) => (
                        <div
                          className="lex flex-col justify-center items-center p-1 border border-gray-200 shadow-xl rounded hover:scale-110 hover:duration-300 delay-75"
                          onClick={(e) => {
                            Item(nearbyRestaurant?.restaurant_id);
                          }}
                        >
                          <div className="w-58 h-58">
                            <img
                              className="w-58 h-48 m-auto"
                              src={`${restaurantImage}${nearbyRestaurant?.restaurant_image}`}
                              alt=""
                            />
                          </div>
                          <div className="text-base font-semibold bg-gradient-to-r text-transparent bg-clip-text from-red-700 to-orange-600 py-1 w-9/12 m-auto">
                            <p>{nearbyRestaurant?.restaurant_name}</p>
                          </div>
                          <div className="text-sm font-light text-gray-600 py-1 w-9/12 m-auto">
                            <p>{nearbyRestaurant?.food_category_name}</p>
                            <p className="lg:block hidden">
                              {nearbyRestaurant?.restaurant_type}
                            </p>
                            <p>{nearbyRestaurant?.distance?.slice(0, 5)} KM</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
