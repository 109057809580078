import axios from "axios";
import logos from "../Assets/logo-wide.png";

// BASE_URL

// export const API_URL = "https://go-passive.apikart.co/v1";
export const API_URL = "https://brbun.in/super_admin/brbun_web_services/brbun_customer/";
export const sliderImage = "https://brbun.in/super_admin/assets/uploads/slider_images/";
export const restaurantImage  = "https://brbun.in/super_admin/assets/uploads/restaurant_images/";
export const FoodMenuImage = "https://brbun.in/super_admin/assets/uploads/food_menu_images/";
export const FoodCategoryImage = "https://brbun.in/super_admin/assets/uploads/food_category_images/";
export const DeliveryBoyImage = "https://brbun.in/super_admin/assets/uploads/delivery_boy_images/";
export const user = JSON.parse(localStorage.getItem("userData"));
export const logosImage = logos;
// Basic Token
var basicToken = "Basic Z29wYXNzaXZlOmdvcGFzc2l2ZUAxMjM0NQ==";
// var bearerToken = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
var bearerToken = "Basic Z29wYXNzaXZlOmdvcGFzc2l2ZUAxMjM0NQ==";
// useEffect(() => {
//   var bearerToken = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
// }, [])
// upload image
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
}

  //################# loginSendOtp #################
  export const loginSendOtpApi = async (requestData) => {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
    try {
      var response = await axios.post(`${API_URL}/loginSendOtp.php`, requestData);
      return response;
    } catch (error) {
      return error?.response;
    }
  }
  //################# logOut #################
  export const logOutApi = async (requestData) => {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
    try {
      var response = await axios.post(`${API_URL}/logOut.php`, requestData);
      return response;
    } catch (error) {
      return error?.response;
    }
  }
  //################# resendOtp #################
  export const resendOtpApi = async (requestData) => {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
    try {
      var response = await axios.post(`${API_URL}/resendOtp.php`, requestData);
      return response;
    } catch (error) {
      return error?.response;
    }
  }
  //################# userRegistration #################
  export const userRegistrationApi = async (requestData) => {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
    try {
      var response = await axios.post(`${API_URL}/userRegistration.php`, requestData);
      return response;
    } catch (error) {
      return error?.response;
    }
  }
  //################# verifyOtp #################
  export const verifyOtpApi = async (requestData) => {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
    try {
      var response = await axios.post(`${API_URL}/verifyOtp.php`, requestData);
      return response;
    } catch (error) {
      return error?.response;
    }
  }
  

// Get  bannerSliderApi
export const bannerSliderApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/bannerSlider.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Get  quickDeliveryRestaurantsApi
export const quickDeliveryRestaurantsApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/quickDeliveryRestaurants.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Get  featuredAdvertiseRestaurantsApi
export const featuredAdvertiseRestaurantsApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/featuredAdvertiseRestaurants.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Get  bestRatedRestaurantsApi
export const bestRatedRestaurantsApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/bestRatedRestaurants.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Get  viewAllApi
export const nearbyRestaurantsApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/nearbyRestaurants.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Get  restaurantsDetails
export const restaurantDetailsApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/restaurantsDetails.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Get  viewAllApi
export const viewAllApi = async (requestData,urlName) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/${urlName}.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Get  myOrderApi
export const myOrderApi = async (requestData,urlName) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/${urlName}.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Get  menuCategoryApi
export const menuCategoryApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/menuCategory.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Get  getCategoryWiseMenuApi
export const getCategoryWiseMenuApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/getCategoryWiseMenu.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Add to cart api
export const cartAddApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/cartAdd_new_json.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
//GetCart
export const cartGetApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/cartGet.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}

//Address Add
export const addressApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/address.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}

// Get  categoryWiseRestaurantsApi
export const categoryWiseRestaurantsApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/categoryWiseRestaurants.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}


// Get  restaurantCategoryApi
export const restaurantCategoryApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("tokenForGopassiveAdmin")}`;
  try {
    var response = await axios.post(`${API_URL}/restaurantCategory.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Add to saveOrderApi
export const saveOrderApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
  var response = await axios.post(`${API_URL}/save_order.php`, requestData);
  return response;
  } catch (error) {
  return error?.response;
  }
  }
 
    //addAddressApi
  export const addAddressApi = async (requestData) => {
    // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
    try {
    var response = await axios.post(`${API_URL}/address.php`, requestData);
    return response;
    } catch (error) {
    return error?.response;
    }
    }

    //search
  export const searchApi = async (requestData) => {
    // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
    try {
    var response = await axios.post(`${API_URL}/search.php`, requestData);
    return response;
    } catch (error) {
    return error?.response;
    }
    }

        //Help Contact 
  export const get_help_contactApi = async (requestData) => {
    // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
    try {
    var response = await axios.post(`${API_URL}/get_help_contact.php`, requestData);
    return response;
    } catch (error) {
    return error?.response;
    }
    }

            //Feedback
  export const insert_feedbackApi = async (requestData) => {
    // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
    try {
    var response = await axios.post(`${API_URL}/insert_feedback.php`, requestData);
    return response;
    } catch (error) {
    return error?.response;
    }
    }
  // Add to OrderDetailApi
export const orderHistoryApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
  var response = await axios.post(`${API_URL}/orderHistory.php`, requestData);
  return response;
  } catch (error) {
  return error?.response;
  }
  }

   // Add to UpdateProfile
export const updateProfileSendOtpApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
  var response = await axios.post(`${API_URL}/updateProfileSendOtp.php`, requestData);
  return response;
  } catch (error) {
  return error?.response;
  }
  }
     // Add to UpdateProfile
export const updateProfileApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
  var response = await axios.post(`${API_URL}/updateProfile.php`, requestData);
  return response;
  } catch (error) {
  return error?.response;
  }
  }

    //deliveryChargeApi
export const deliveryChargeApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
  var response = await axios.post(`${API_URL}/deliveryCharge.php`, requestData);
  return response;
  } catch (error) {
  return error?.response;
  }
  }
//deleteAddressApi
export const deleteAddressApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/deleteAddress.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
//defaultAddressApi
export const defaultAddressApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/defaultAddress.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
//updateAddressApi
export const updateAddressApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/updateAddress.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}

// Add to saveOrderDetails_newApi
export const saveOrderDetails_newApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/saveOrderDetails_new.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
  // Add to cartUpdateApi
export const cartUpdateApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/cartUpdate.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Add to cartClearApi
export const cartClearApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/cartClear.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Add to cartClearApi
export const cartClearAllApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/cartClearAll.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Add to saveOrderDetailsApi
export const saveOrderDetailsApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/saveOrderDetails.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
  
// Add to extraitem
export const getExtraItemApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/getExtraItem.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
// Add to OrderDetail
export const orderDetailsApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/orderDetails.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}

// Add to cancelOrder
export const cancelOrderApi = async (requestData) => {
  // axios.defaults.headers.common["Authorization"] = Bearer ${localStorage.getItem("tokenForGopassiveAdmin")};
  try {
    var response = await axios.post(`${API_URL}/cancelOrder.php`, requestData);
    return response;
  } catch (error) {
    return error?.response;
  }
}
  
  












